<script setup lang="ts">
import { momentFormat } from '~/utils/filters'
import { useTimeAgo } from '@vueuse/core'
import { computed } from 'vue'

interface Props {
  date: string,
  icon?: boolean
}

const props = defineProps({
  date: null,
  icon: { type: Boolean, default: false }
})

const date = computed(() => new Date(props.date))
// TODO (wvffle): Translate useTimeAgo
const realDate = useTimeAgo(date)
</script>

<template>
  <time
    :datetime="date"
    :title="momentFormat(date)"
  >
    <i
      v-if="props.icon"
      class="outline clock icon"
    />
    {{ realDate }}
  </time>
</template>
