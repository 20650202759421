{
  "App": {
    "loading": "Loading…"
  },
  "components": {
    "About": {
      "description": {
        "findApp": "Use Funkwhale on other devices with our apps.",
        "funkwhale": "Funkwhale is a community-driven project that lets you listen and share music and audio within a decentralized, open network.",
        "publicContent": "Listen to public albums and playlists shared on this pod.",
        "quota": "Users on this pod also get {quota} of free storage to upload their own content!",
        "signup": "Sign up now to keep a track of your favorites, create playlists, discover new content and much more!"
      },
      "header": {
        "aboutPod": "About this pod",
        "findApp": "Find an app",
        "funkwhale": "A social platform to enjoy and share music",
        "publicContent": "Browse public content",
        "signup": "Sign up"
      },
      "help": {
        "closedRegistrations": "Registrations are closed on this pod. You can signup on another pod using the link below."
      },
      "link": {
        "findOtherPod": "Find another pod",
        "learnMore": "Learn more"
      },
      "message": {
        "greeting": "Hello {username}",
        "loggedIn": "You're already signed in!"
      },
      "placeholder": {
        "noDescription": "No description available"
      },
      "stat": {
        "activeUsers": "No active users | {n} active user | {n} active users",
        "hoursOfMusic": "0 hours of music | {n} hour of music | {n} hours of music"
      },
      "title": "About"
    },
    "AboutPod": {
      "feature": {
        "allowList": "Allow-list",
        "anonymousAccess": "Anonymous access",
        "federation": "Federation",
        "quota": "Upload quota",
        "registrations": "Registrations",
        "status": {
          "closed": "Closed",
          "disabled": "Disabled",
          "enabled": "Enabled",
          "open": "Open"
        },
        "version": "Funkwhale version"
      },
      "header": {
        "about": "About this pod",
        "contact": "Contact",
        "features": "Features",
        "rules": "Rules",
        "statistics": "Statistics",
        "terms": "Terms and privacy policy"
      },
      "link": {
        "about": "About this pod",
        "features": "Features",
        "introduction": "Introduction",
        "rules": "Rules",
        "statistics": "Statistics",
        "terms": "Terms and privacy policy"
      },
      "message": {
        "contact": "Send us an email: { contactEmail }"
      },
      "notApplicable": "N/A",
      "placeholder": {
        "noDescription": "No description available",
        "noRules": "No rules available",
        "noTerms": "No terms available"
      },
      "stat": {
        "activeUsers": "No active users | {n} active user | {n} active users",
        "albumsCount": "0 albums | {n} album | {n} albums",
        "artistsCount": "0 artists | {n} artist | {n} artists",
        "hoursOfMusic": "0 hours of music | {n} hour of music | {n} hours of music",
        "listeningsCount": "0 listenings | {n} listening | {n} listenings",
        "tracksCount": "0 tracks | {n} track | {n} tracks"
      },
      "title": "About"
    },
    "Home": {
      "description": {
        "funkwhale": {
          "paragraph1": "This pod runs Funkwhale, a community-driven project that lets you listen and share music and audio within a decentralized, open network.",
          "paragraph2": "Funkwhale is free and developed by a friendly community of volunteers."
        },
        "quota": "Users on this pod also get {quota} of free storage to upload their own content!",
        "signup": "Sign up now to keep a track of your favorites, create playlists, discover new content and much more!"
      },
      "header": {
        "about": "About this Funkwhale pod",
        "aboutFunkwhale": "About Funkwhale",
        "contact": "Contact",
        "links": "Useful links",
        "login": "Log in",
        "newAlbums": "Recently added albums",
        "newChannels": "New channels",
        "signup": "Sign up",
        "statistics": "Statistics",
        "welcome": "Welcome to {podName}!"
      },
      "help": {
        "registrationsClosed": "Registrations are closed on this pod. You can signup on another pod using the link below."
      },
      "link": {
        "findOtherPod": "Find another pod",
        "funkwhale": "Visit funkwhale.audio",
        "learnMore": "Learn more",
        "mobileApps": {
          "description": "Use Funkwhale on other devices with our apps",
          "label": "Mobile apps"
        },
        "publicContent": {
          "description": "Listen to public albums and playlists shared on this pod.",
          "label": "Browse public content"
        },
        "rules": "Server rules",
        "userGuides": {
          "description": "Discover everything you need to know about Funkwhale and its features",
          "label": "User guides"
        },
        "viewMore": "View more…"
      },
      "placeholder": {
        "noDescription": "No description available"
      },
      "stat": {
        "activeUsers": "No active users | {n} active user | {n} active users",
        "hoursOfMusic": "0 hours of music | {n} hour of music | {n} hours of music"
      },
      "title": "Home"
    },
    "PageNotFound": {
      "header": {
        "pageNotFound": "Page not found!"
      },
      "link": {
        "home": "Go to home page"
      },
      "message": {
        "pageNotFound": "Sorry, the page you asked for does not exist:"
      },
      "title": "Page not found"
    },
    "Queue": {
      "button": {
        "clear": "Clear",
        "close": "Close",
        "stopRadio": "Stop radio"
      },
      "header": {
        "failure": "The track cannot be loaded",
        "noSources": "The track has no available sources.",
        "radio": "You have a radio playing"
      },
      "label": {
        "addArtistContentFilter": "Hide content from this artist…",
        "duration": "Duration",
        "enterFullscreen": "Enter fullscreen mode",
        "exitFullscreen": "Exit fullscreen mode",
        "favorite": "Favorite track",
        "next": "Next track",
        "pause": "Pause",
        "play": "Play",
        "populatingRadio": "Fetching radio track…",
        "previous": "Previous track",
        "queue": "Queue",
        "remove": "Remove",
        "restart": "Restart track",
        "selectTrack": "Select track",
        "showCoverArt": "Show Cover Art",
        "showVisualizer": "Show MoonDrop visualizer"
      },
      "message": {
        "automaticPlay": "The next track will play automatically in a few seconds…",
        "radio": "New tracks will be appended here automatically.",
        "webglUnsupported": "Your browser doesn't appear to support WebGL2."
      },
      "meta": {
        "end": "End",
        "queuePosition": "Track {index} of {length}",
        "startTime": "00:00",
        "unknownAlbum": "Unknown Album",
        "unknownArtist": "Unknown Artist"
      },
      "warning": {
        "connectivity": "You may have a connectivity issue."
      }
    },
    "RemoteSearchForm": {
      "button": {
        "fediverse": "Fediverse",
        "rss": "RSS",
        "search": "Search"
      },
      "description": {
        "fediverse": "Use this form to subscribe to a channel hosted somewhere else on the Fediverse.",
        "rss": "Use this form to subscribe to an RSS feed from its URL."
      },
      "error": {
        "fetchFailed": "This object cannot be retrieved"
      },
      "header": {
        "fetchFailed": "Error while fetching object"
      },
      "label": {
        "fediverse": {
          "fieldLabel": "Fediverse object",
          "fieldPlaceholder": "{'@'}username{'@'}example.com",
          "title": "Fediverse object"
        },
        "rss": {
          "fieldLabel": "RSS feed location",
          "fieldPlaceholder": "https://website.example.com/rss.xml",
          "title": "Subscribe to a podcast RSS feed"
        }
      },
      "warning": {
        "unsupported": "This kind of object isn't supported yet"
      }
    },
    "SetInstanceModal": {
      "button": {
        "cancel": "Cancel",
        "submit": "Submit"
      },
      "header": {
        "chooseInstance": "Choose your instance",
        "failure": "It is not possible to connect to the given URL",
        "suggestions": "Suggested choices"
      },
      "help": {
        "notFunkwhaleServer": "The given address is not a Funkwhale server",
        "selectPod": "To continue, please select the Funkwhale instance you want to connect to. Enter the address directly, or select one of the suggested choices.",
        "serverDown": "The server might be down"
      },
      "label": {
        "url": "Instance URL"
      },
      "message": {
        "currentConnection": "You are currently connected to { 0 }. If you continue, you will be disconnected from your current instance and all your local data will be deleted.",
        "newUrl": "You are now using the Funkwhale instance at {url}"
      }
    },
    "ShortcutsModal": {
      "button": {
        "close": "Close"
      },
      "header": {
        "modal": "Keyboard shortcuts"
      },
      "shortcut": {
        "audio": {
          "clearQueue": "Clear queue",
          "decreaseVolume": "Decrease volume",
          "expandQueue": "Expand queue/player view",
          "increaseVolume": "Increase volume",
          "label": "Audio player shortcuts",
          "playNext": "Play next track",
          "playPause": "Pause/play the current track",
          "playPrevious": "Play previous track",
          "seekBack30": "Seek backwards 30s",
          "seekBack5": "Seek backwards 5s",
          "seekForward30": "Seek forwards 30s",
          "seekForward5": "Seek forwards 5s",
          "shuffleQueue": "Shuffle queue",
          "toggleFavorite": "Toggle favorite",
          "toggleLoop": "Toggle queue looping",
          "toggleMute": "Toggle mute"
        },
        "general": {
          "focus": "Focus searchbar",
          "label": "General shortcuts",
          "show": "Show available keyboard shortcuts",
          "unfocus": "Unfocus searchbar"
        }
      }
    },
    "Sidebar": {
      "header": {
        "administration": "Administration",
        "explore": "Explore",
        "library": "My library",
        "main": "Main navigation",
        "more": "More"
      },
      "label": {
        "add": "Add content",
        "administration": "Administration",
        "edits": "Pending review edits",
        "follows": "Pending follow requests",
        "language": "Language",
        "main": "Main menu",
        "play": "Play this track",
        "reports": "Pending review reports",
        "theme": "Theme"
      },
      "link": {
        "about": "About this pod",
        "albums": "Albums",
        "artists": "Artists",
        "browse": "Browse",
        "channels": "Channels",
        "createAccount": "Create account",
        "favorites": "Favorites",
        "home": "Home",
        "library": "Library",
        "login": "Login",
        "moderation": "Moderation",
        "playlists": "Playlists",
        "podcasts": "Podcasts",
        "radios": "Radios",
        "search": "Search",
        "settings": "Settings",
        "switchInstance": "Switch instance",
        "users": "Users"
      }
    },
    "admin": {
      "SettingsGroup": {
        "button": {
          "save": "Save"
        },
        "header": {
          "error": "Error while saving settings.",
          "image": "Current image"
        },
        "message": {
          "success": "Settings updated successfully."
        }
      },
      "SignupFormBuilder": {
        "button": {
          "add": "Add a new field",
          "edit": "Edit form",
          "preview": "Preview Form"
        },
        "help": {
          "additionalFields": "Additional form fields to be displayed in the form. Only shown if manual sign-up validation is enabled.",
          "helpText": "An optional text to be displayed at the start of the sign-up form."
        },
        "label": {
          "additionalField": "Additional field",
          "additionalFields": "Additional fields",
          "delete": "Delete",
          "helpText": "Help text",
          "moveDown": "Move down",
          "moveUp": "Move up"
        },
        "table": {
          "additionalFields": {
            "header": {
              "actions": "Actions",
              "label": "Field label",
              "required": "Required",
              "type": "Field type"
            },
            "required": {
              "false": "False",
              "true": "True"
            },
            "type": {
              "long": "Long text",
              "short": "Short text"
            }
          }
        }
      }
    },
    "audio": {
      "ChannelCard": {
        "meta": {
          "episodes": "No episodes | {n} episode | {n} episodes",
          "tracks": "No tracks | {n} track | {n} tracks"
        },
        "title": "Updated on {date}"
      },
      "ChannelEntries": {
        "help": {
          "subscribe": "You may need to subscribe to this channel to see its content."
        }
      },
      "ChannelForm": {
        "header": {
          "error": "Error while saving channel."
        },
        "help": {
          "discography": "Publish music you make as a nice discography of albums and singles.",
          "podcast": "Host your episodes and keep your community updated.",
          "podcastFields": "Used for the itunes:email and itunes:name field required by certain platforms such as Spotify or iTunes.",
          "username": "Used in URLs and to follow this channel in the Fediverse. It cannot be changed later."
        },
        "label": {
          "category": "Category",
          "description": "Description",
          "discography": "Artist Discography",
          "email": "Owner e-mail address",
          "image": "Channel Picture",
          "language": "Language",
          "name": "Name",
          "owner": "Owner name",
          "podcast": "Podcasts",
          "subcategory": "Subcategory",
          "tags": "Tags",
          "username": "Fediverse handle"
        },
        "legend": {
          "purpose": "What will this channel be used for?"
        },
        "loader": {
          "loading": "Loading"
        },
        "placeholder": {
          "name": "Awesome channel name",
          "username": "awesomechannelname"
        }
      },
      "ChannelSerieCard": {
        "meta": {
          "episodes": "No episodes | {n} episode | {n} episodes"
        }
      },
      "ChannelSeries": {
        "button": {
          "showMore": "Show more"
        },
        "help": {
          "subscribe": "You may need to subscribe to this channel to see its contents."
        }
      },
      "ChannelsWidget": {
        "button": {
          "showMore": "Show more"
        }
      },
      "EmbedWizard": {
        "button": {
          "copy": "Copy"
        },
        "header": {
          "preview": "Preview"
        },
        "help": {
          "anonymous": "Please contact your admins and ask them to update the corresponding setting.",
          "embed": "Copy/paste this code in your website HTML",
          "width": "Leave empty for a responsive widget"
        },
        "label": {
          "embed": "Embed code",
          "height": "Widget height",
          "width": "Widget width"
        },
        "message": {
          "copy": "Text copied to clipboard!"
        },
        "warning": {
          "anonymous": "Sharing will not work because this pod doesn't allow anonymous users to access content."
        }
      },
      "LibraryFollowButton": {
        "button": {
          "cancel": "Cancel follow request",
          "follow": "Follow",
          "unfollow": "Unfollow"
        }
      },
      "PlayButton": {
        "button": {
          "addToPlaylist": "Add to playlist",
          "addToQueue": "Add to current queue",
          "discretePlay": "Play",
          "episodeDetails": "Episode details",
          "hideArtist": "Hide content from this artist",
          "playAlbum": "Play album",
          "playArtist": "Play artist",
          "playNext": "Play next",
          "playNow": "Play now",
          "playPlaylist": "Play playlist",
          "playTrack": "Play track",
          "playTracks": "Play tracks",
          "report": "Report…",
          "startRadio": "Play similar songs",
          "trackDetails": "Track details"
        },
        "title": {
          "more": "More…",
          "unavailable": "This track is not available in any library you have access to"
        }
      },
      "Player": {
        "header": {
          "player": "Audio player and controls"
        },
        "label": {
          "addArtistContentFilter": "Hide content from this artist…",
          "audioPlayer": "Media player",
          "clearQueue": "Clear your queue",
          "expandQueue": "Expand queue",
          "loopingDisabled": "Looping disabled. Click to switch to single-track looping.",
          "loopingSingle": "Looping on a single track. Click to switch to whole queue looping.",
          "loopingWholeQueue": "Looping on whole queue. Click to disable looping.",
          "mute": "Mute",
          "nextTrack": "Next track",
          "pause": "Pause",
          "play": "Play",
          "previousTrack": "Previous track",
          "shuffleQueue": "Shuffle your queue",
          "unmute": "Unmute"
        },
        "meta": {
          "position": "{index} of {length}",
          "unknownAlbum": "Unknown Album",
          "unknownArtist": "Unknown Artist"
        }
      },
      "PlayerControls": {
        "labels": {
          "next": "Next track",
          "pause": "Pause",
          "play": "Play",
          "previous": "Previous track"
        }
      },
      "Search": {
        "empty": {
          "noAlbums": "No album matched your query",
          "noArtists": "No artist matched your query"
        },
        "header": {
          "albums": "Albums",
          "artists": "Artists",
          "search": "Search for some music"
        },
        "placeholder": {
          "search": "Artist, album, track…"
        }
      },
      "SearchBar": {
        "empty": {
          "noResults": "Sorry, there are no results for this search"
        },
        "header": {
          "noResults": "No matches found"
        },
        "label": {
          "album": "Album",
          "artist": "Artist",
          "category": {
            "federation": "Federation",
            "podcasts": "Podcasts"
          },
          "search": "Search for content",
          "tag": "Tag",
          "track": "Track"
        },
        "link": {
          "fediverse": "Search on the fediverse",
          "more": "More results 🡒",
          "rss": "Subscribe to podcast via RSS"
        },
        "placeholder": {
          "search": "Search for artists, albums, tracks…"
        }
      },
      "VolumeControl": {
        "button": {
          "mute": "Mute",
          "unmute": "Unmute"
        },
        "label": {
          "slider": "Adjust volume"
        }
      },
      "album": {
        "Card": {
          "meta": {
            "tracks": "No tracks | {n} track | {n} tracks"
          }
        },
        "Widget": {
          "button": {
            "more": "Show more"
          }
        }
      },
      "artist": {
        "Card": {
          "meta": {
            "episodes": "No episodes | {n} episode | {n} episodes",
            "tracks": "No tracks | {n} track | {n} tracks"
          }
        },
        "Widget": {
          "button": {
            "more": "Show more"
          }
        }
      },
      "podcast": {
        "MobileRow": {
          "button": {
            "actions": "Show track actions"
          }
        },
        "Modal": {
          "button": {
            "addToFavorites": "Add to favorites",
            "addToPlaylist": "Add to playlist",
            "addToQueue": "Add to queue",
            "albumDetails": "View album",
            "artistDetails": "View artist",
            "channelDetails": "View channel",
            "episodeDetails": "Episode details",
            "playNext": "Play next",
            "playNow": "Play now",
            "removeFromFavorites": "Remove from favorites",
            "seriesDetails": "View series",
            "startRadio": "Play radio",
            "trackDetails": "Track details"
          }
        }
      },
      "track": {
        "MobileRow": {
          "button": {
            "actions": "Show track actions"
          }
        },
        "Modal": {
          "button": {
            "addToFavorites": "Add to favorites",
            "addToPlaylist": "Add to playlist",
            "addToQueue": "Add to queue",
            "albumDetails": "View album",
            "artistDetails": "View artist",
            "channelDetails": "View channel",
            "episodeDetails": "Episode details",
            "playNext": "Play next",
            "playNow": "Play now",
            "removeFromFavorites": "Remove from favorites",
            "seriesDetails": "View series",
            "startRadio": "Play radio",
            "trackDetails": "Track details"
          }
        },
        "Table": {
          "table": {
            "header": {
              "album": "Album",
              "artist": "Artist",
              "title": "Title"
            }
          }
        },
        "Widget": {
          "button": {
            "more": "Show more"
          },
          "empty": {
            "noResults": "Nothing found"
          }
        }
      }
    },
    "auth": {
      "ApplicationEdit": {
        "button": {
          "regenerateToken": "Regenerate token"
        },
        "header": {
          "appDetails": "Application Details",
          "appSecretWarning": "Keep a copy of this token in a safe place.",
          "editApp": "Edit application"
        },
        "help": {
          "appDetails": "Application ID and secret are really sensitive values and must be treated like passwords. Do not share those with anyone else."
        },
        "label": {
          "accessToken": "Access token",
          "appId": "Application ID",
          "appSecret": "Application secret"
        },
        "link": {
          "settings": "Back to settings"
        },
        "message": {
          "appSecretWarning": "You won't be able to see it again once you leave this screen."
        },
        "title": "Edit application"
      },
      "ApplicationForm": {
        "button": {
          "create": "Create application",
          "update": "Update application"
        },
        "header": {
          "failure": "We cannot save your changes"
        },
        "help": {
          "redirectUri": "Use \"urn:ietf:wg:oauth:2.0:oob\" as a redirect URI if your application is not served on the web."
        },
        "label": {
          "name": "Name",
          "redirectUri": "Redirect URI",
          "scopes": {
            "description": "Checking the parent \"Read\" or \"Write\" scopes implies access to all the corresponding children scopes.",
            "label": "Scopes",
            "read": {
              "description": "Read-only access to user data",
              "label": "Read"
            },
            "write": {
              "description": "Write-only access to user data",
              "label": "Write"
            }
          }
        }
      },
      "ApplicationNew": {
        "link": {
          "settings": "Back to settings"
        },
        "title": "Create a new application"
      },
      "Authorize": {
        "button": {
          "authorize": "Authorize {app}"
        },
        "header": {
          "access": "{app_name} wants to access your Funkwhale account",
          "allScopes": "Full access",
          "authorize": "Authorize third-party app",
          "authorizeFailure": "Error while fetching application data",
          "fetchFailure": "Error while fetching application data",
          "readOnly": "Read-only",
          "writeOnly": "Write-only"
        },
        "help": {
          "copyCode": "You will be shown a code to copy-past in the application",
          "pasteCode": "Copy-paste the following code in the application:",
          "redirect": "You will be redirected to { 0 }"
        },
        "message": {
          "unknownPermissions": "The application is also requesting the following unknown permissions:"
        },
        "title": "Allow application"
      },
      "LoginForm": {
        "button": {
          "login": "Login"
        },
        "header": {
          "loginFailure": "We cannot log you in"
        },
        "help": {
          "approvalRequired": "If you signed-up recently, you may need to wait before our moderation team review your account, or verify your e-mail address.",
          "invalidCredentials": "Please double-check that your username and password combination is correct and make sure you verified your e-mail address."
        },
        "label": {
          "password": "Password",
          "username": "Username or e-mail address"
        },
        "link": {
          "createAccount": "Create an account",
          "resetPassword": "Reset your password"
        },
        "message": {
          "redirect": "You will be redirected to {domain} to authenticate"
        },
        "placeholder": {
          "username": "Enter your username or e-mail address"
        }
      },
      "Logout": {
        "button": {
          "logout": "Yes, log me out!"
        },
        "header": {
          "confirm": "Are you sure you want to log out?",
          "unauthenticated": "You aren't currently logged in"
        },
        "link": {
          "login": "Log in!"
        },
        "message": {
          "loggedIn": "You are currently logged in as {username}"
        },
        "title": "Log out"
      },
      "Plugin": {
        "button": {
          "save": "Save",
          "scan": "Scan"
        },
        "description": {
          "library": "Library where files should be imported."
        },
        "header": {
          "failure": "Error while saving plugin"
        },
        "label": {
          "library": "Library",
          "pluginEnabled": "Enabled"
        },
        "link": {
          "documentation": "Documentation"
        }
      },
      "Settings": {
        "button": {
          "delete": "Delete",
          "deleteAccount": "Delete my account…",
          "deleteAccountConfirm": "Delete my account",
          "disableSubsonic": "Disable access",
          "edit": "Edit",
          "password": "Change password",
          "refresh": "Refresh",
          "remove": "Remove",
          "removeApp": "Remove application",
          "revoke": "Revoke",
          "revokeAccess": "Revoke access",
          "update": "Update",
          "updateSettings": "Update settings"
        },
        "description": {
          "authorizedApps": "This is the list of applications that have access to your account data.",
          "changeEmail": "Change the e-mail address associated with your account. We will send a confirmation to the new address.",
          "changePassword": {
            "paragraph1": "Changing your password will also change your Subsonic API password if you have requested one.",
            "paragraph2": "You will have to update your password on your clients that use this password."
          },
          "contentFilters": "Content filters help you hide content you don't want to see on the service.",
          "deleteAccount": "You can permanently and irreversibly delete your account and all the associated data using the form below. You will be asked for confirmation.",
          "plugins": "Use plugins to extend Funkwhale and get additional features.",
          "yourApps": "This is the list of applications that you have registered."
        },
        "header": {
          "accountFailure": "We cannot delete your account",
          "accountSettings": "Account settings",
          "authorizedApps": "Authorized apps",
          "avatar": "Avatar",
          "avatarFailure": "Your avatar cannot be saved",
          "changeEmail": "Change my e-mail address",
          "changePassword": "Change my password",
          "contentFilters": "Content filters",
          "deleteAccount": "Delete my account",
          "emailFailure": "We cannot change your e-mail address",
          "hiddenArtists": "Hidden artists",
          "noApps": "You don't have any application connected with your account.",
          "noPersonalApps": "You don't have registered any application yet.",
          "passwordFailure": "Your password cannot be changed",
          "plugins": "Plugins",
          "settingsUpdated": "Settings updated",
          "updateFailure": "Your settings can't be updated",
          "yourApps": "Your applications"
        },
        "help": {
          "changePassword": "Please double-check your password is correct",
          "noApps": "If you authorize third-party applications to access your data, those applications will be listed here.",
          "noPersonalApps": "Register one to integrate Funkwhale with third-party applications."
        },
        "label": {
          "avatar": "Avatar",
          "currentPassword": "Current password",
          "newEmail": "New e-mail address",
          "newPassword": "New password",
          "password": "Password"
        },
        "link": {
          "managePlugins": "Manage plugins",
          "newApp": "Register a new application"
        },
        "message": {
          "confirmDelete": "Your deletion request was submitted, your account and content will be deleted shortly",
          "currentEmail": "Your current e-mail address is {email}"
        },
        "modal": {
          "changePassword": {
            "content": {
              "logout": "You will be logged out from this session and have to log in with the new one",
              "subsonic": "Your Subsonic password will be changed to a new, random one, logging you out from devices that used the old Subsonic password",
              "warning": "Changing your password will have the following consequences"
            },
            "header": "Change your password?"
          },
          "deleteAccount": {
            "content": {
              "warning": "This is irreversible and will permanently remove your data from our servers. You will we immediatly logged out."
            },
            "header": "Do you want to delete your account?"
          },
          "deleteApp": {
            "content": {
              "warning": "This will permanently remove the application and all the associated tokens."
            },
            "header": "Remove application {app}?"
          },
          "revokeApp": {
            "content": {
              "warning": "This will prevent this application from accessing the service on your behalf."
            },
            "header": "Revoke access for application {app}?"
          }
        },
        "table": {
          "artists": {
            "header": {
              "creationDate": "Creation date",
              "name": "Name"
            }
          },
          "authorizedApps": {
            "header": {
              "application": "Application",
              "permissions": "Permissions"
            }
          },
          "yourApps": {
            "header": {
              "application": "Application",
              "creationDate": "Creation date",
              "scopes": "Scopes"
            }
          }
        },
        "title": "Account Settings",
        "warning": {
          "deleteAccount": "Your account will be deleted from our servers within a few minutes. We will also notify other servers who may have a copy of some of your data so they can proceed to deletion. Please note that some of these servers may be offline or unwilling to comply though."
        }
      },
      "SignupForm": {
        "button": {
          "create": "Create my account"
        },
        "header": {
          "login": "Log in to your Funkwhale account",
          "signupFailure": "Your account cannot be created."
        },
        "label": {
          "email": "E-mail address",
          "invitation": "Invitation code",
          "password": "Password",
          "username": "Username"
        },
        "message": {
          "accountCreated": "Your account was successfully created. Please verify your e-mail address before trying to login.",
          "awaitingReview": "Your account request was successfully submitted. You will be notified by e-mail when our moderation team has reviewed your request.",
          "registrationClosed": "Public registrations are not possible on this instance. You will need an invitation code to sign up.",
          "requiresReview": "Registrations on this pod are open, but reviewed by moderators before approval."
        },
        "placeholder": {
          "email": "Enter your e-mail address",
          "invitation": "Enter your invitation code (case insensitive)",
          "username": "Enter your username"
        }
      },
      "SubsonicTokenForm": {
        "button": {
          "confirmDisable": "Disable access",
          "confirmNewPassword": "Request a password",
          "disable": "Disable Subsonic access",
          "newPassword": "Request a new password"
        },
        "description": {
          "subsonic": {
            "paragraph1": "Funkwhale is compatible with other music players that support the Subsonic API.",
            "paragraph2": "You can use those to enjoy your playlist and music in offline mode, on your smartphone or tablet, for instance.",
            "paragraph3": "However, accessing Funkwhale from those clients requires a separate password you can set below."
          }
        },
        "header": {
          "error": "Error",
          "subsonic": "Subsonic API password"
        },
        "label": {
          "subsonicField": "Your subsonic API password"
        },
        "link": {
          "apps": "Discover how to use Funkwhale from other apps"
        },
        "message": {
          "accessDisabled": "Access disabled",
          "passwordUpdated": "Password updated",
          "unavailable": "The Subsonic API is not available on this Funkwhale instance."
        },
        "modal": {
          "disableSubsonic": {
            "content": {
              "warning": "This will completely disable access to the Subsonic API using from account."
            },
            "header": "Disable Subsonic API access?"
          },
          "newPassword": {
            "content": {
              "warning": "This will log you out from existing devices that use the current password."
            },
            "header": "Request a new Subsonic API password?"
          }
        }
      }
    },
    "channels": {
      "AlbumForm": {
        "header": {
          "error": "Error while creating"
        },
        "label": {
          "albumTitle": "Title"
        }
      },
      "AlbumModal": {
        "button": {
          "cancel": "Cancel",
          "create": "Create"
        },
        "header": {
          "newAlbum": "New album",
          "newSeries": "New series"
        }
      },
      "AlbumSelect": {
        "label": {
          "album": "Album",
          "series": "Series"
        },
        "meta": {
          "tracks": "No tracks | {n} track | {n} tracks"
        },
        "option": {
          "none": "None"
        }
      },
      "LicenseSelect": {
        "label": {
          "license": "Licence"
        },
        "link": {
          "license": "About this license"
        },
        "option": {
          "none": "None"
        }
      },
      "SubscribeButton": {
        "help": {
          "auth": "You need to be logged in to subscribe to this channel"
        },
        "title": {
          "subscribe": "Subscribe",
          "unsubscribe": "Unsubscribe"
        }
      },
      "UploadForm": {
        "button": {
          "edit": "Edit",
          "ignore": "Ignore",
          "remove": "Remove",
          "resume": "Resume",
          "retry": "Retry"
        },
        "description": {
          "extensions": "Supported extensions {extensions}"
        },
        "header": {
          "error": "Error while publishing"
        },
        "help": {
          "license": "Add a license to your upload to ensure some freedoms to your public."
        },
        "label": {
          "channel": "Channel",
          "openBrowser": "Browse…"
        },
        "message": {
          "dragAndDrop": "Drag and drop your files here or open the browser to upload your files",
          "pending": "You have some draft uploads pending publication."
        },
        "status": {
          "errored": "Errored",
          "pending": "Pending",
          "uploading": "Uploading"
        },
        "warning": {
          "quota": "You don't have any space left to upload your files. Please contact the moderators."
        }
      },
      "UploadMetadataForm": {
        "label": {
          "description": "Description",
          "image": "Track Picture",
          "position": "Position",
          "tags": "Tags",
          "title": "Title"
        }
      },
      "UploadModal": {
        "button": {
          "cancel": "Cancel",
          "close": "Close",
          "finishLater": "Finish later",
          "next": "Next",
          "previous": "Previous step",
          "publish": "Publish",
          "update": "Update"
        },
        "header": {
          "processing": "Processing uploads",
          "publish": "Publish audio",
          "uploadDetails": "Upload details",
          "uploadFiles": "Files to upload"
        },
        "meta": {
          "files": "no files | {n} file | {n} files",
          "quota": "Remaining storage space: {space}"
        }
      }
    },
    "common": {
      "ActionTable": {
        "button": {
          "allSelected": "No elements selected | {n} element selected | All {n} elements selected",
          "go": "Go",
          "launch": "Launch",
          "refresh": "Refresh table content",
          "select": "Select",
          "selectAll": "Select all items",
          "selectCurrentPage": "Select only current page",
          "selectElement": "Select one element | Select all {n} elements",
          "selected": "{ n } on {total} selected"
        },
        "header": {
          "error": "Error while applying action"
        },
        "label": {
          "actions": "Actions",
          "performAction": "Perform actions"
        },
        "message": {
          "needsRefresh": "Content has been updated, click refresh to see up-to-date content",
          "success": "Action {action} was launched successfully on {n} element | Action {action} was launched successfully on {n} elements"
        },
        "modal": {
          "performAction": {
            "content": {
              "warning": "This may affect a lot of elements or have irreversible consequences, please double check this is really what you want."
            },
            "header": "Do you want to launch {action} on {n} element? | Do you want to launch {action} on {n} elements?"
          }
        }
      },
      "AttachmentInput": {
        "button": {
          "remove": "Remove"
        },
        "header": {
          "failure": "Your attachment cannot be saved"
        },
        "help": {
          "upload": "PNG or JPG. Dimensions should be between 1400x1400px and 3000x3000px. Maximum file size allowed is 5MB."
        },
        "label": {
          "upload": "Upload New Picture…"
        },
        "loader": {
          "uploading": "Uploading file…"
        }
      },
      "CollapseLink": {
        "button": {
          "collapse": "Collapse",
          "expand": "Expand"
        }
      },
      "ContentForm": {
        "button": {
          "preview": "Preview",
          "write": "Write"
        },
        "empty": {
          "noContent": "Nothing to preview"
        },
        "help": {
          "markdown": "Markdown syntax is supported"
        },
        "placeholder": {
          "input": "Write a few words here…"
        }
      },
      "CopyInput": {
        "button": {
          "copy": "Copy"
        },
        "message": {
          "success": "Text copied to clipboard!"
        }
      },
      "DangerousButton": {
        "button": {
          "cancel": "Cancel",
          "confirm": "Confirm"
        },
        "header": {
          "confirm": "Do you want to confirm this action?"
        }
      },
      "Duration": {
        "meta": {
          "hours": "{hours} h {minutes} min",
          "minutes": "{minutes} min"
        }
      },
      "EmptyState": {
        "button": {
          "refresh": "Refresh"
        },
        "header": {
          "noResults": "No results were found."
        }
      },
      "ExpandableDiv": {
        "button": {
          "less": "Show less",
          "more": "Show more"
        }
      },
      "InlineSearchBar": {
        "button": {
          "clear": "Clear"
        },
        "label": {
          "search": "Search"
        },
        "placeholder": {
          "search": "Search…"
        }
      },
      "LoginModal": {
        "description": {
          "noAccess": "You don't have access"
        },
        "header": {
          "unauthenticated": "Unauthenticated"
        },
        "link": {
          "login": "Log in",
          "signup": "Sign up"
        }
      },
      "RenderedDescription": {
        "button": {
          "cancel": "Cancel",
          "edit": "Edit",
          "less": "Show less",
          "more": "Show more",
          "update": "Update description"
        },
        "empty": {
          "noDescription": "No description available"
        },
        "header": {
          "failure": "Error while updating description"
        }
      },
      "UserLink": {
        "link": {
          "username": "{'@'}{username}"
        }
      },
      "UserMenu": {
        "label": {
          "language": "Change language",
          "shortcuts": "Keyboard shortcuts",
          "theme": "Change theme"
        },
        "link": {
          "about": "About",
          "chat": "Chat room",
          "docs": "Documentation",
          "forum": "Forum",
          "git": "Issue tracker",
          "login": "Log in",
          "logout": "Log out",
          "notifications": "Notifications",
          "profile": "Profile",
          "settings": "Settings",
          "signup": "Sign up",
          "support": "Help"
        }
      },
      "UserModal": {
        "button": {
          "switchInstance": "Use another instance"
        },
        "header": {
          "options": "Options"
        },
        "label": {
          "language": "Change language",
          "shortcuts": "Keyboard shortcuts",
          "theme": "Change theme"
        },
        "link": {
          "about": "About",
          "chat": "Chat room",
          "docs": "Documentation",
          "forum": "Forum",
          "git": "Issue tracker",
          "login": "Log in",
          "logout": "Log out",
          "notifications": "Notifications",
          "profile": "Profile",
          "settings": "Settings",
          "signup": "Sign up",
          "support": "Help"
        }
      }
    },
    "favorites": {
      "List": {
        "empty": {
          "noFavorites": "No tracks have been added to your favorites yet"
        },
        "header": {
          "favorites": "No favorites | {n} favorite | {n} favorites"
        },
        "link": {
          "library": "Browse the library"
        },
        "loader": {
          "loading": "Loading your favorites…"
        },
        "ordering": {
          "direction": {
            "ascending": "Ascending",
            "descending": "Descending",
            "label": "Order"
          },
          "label": "Ordering"
        },
        "pagination": {
          "results": "Results per page"
        },
        "title": "Your favorites"
      },
      "TrackFavoriteIcon": {
        "button": {
          "add": "Add to favorites",
          "remove": "Remove from favorites"
        },
        "label": {
          "inFavorites": "In favorites"
        }
      }
    },
    "federation": {
      "FetchButton": {
        "button": {
          "close": "Close",
          "reload": "Close and reload page"
        },
        "description": {
          "failure": "An error occurred while trying to refresh data:",
          "pending": "The refresh request hasn't been processed in time by our server. It will be processed later.",
          "skipped": "The remote server answered, but returned data was unsupported by Funkwhale.",
          "success": "Data was refreshed successfully from remote server."
        },
        "header": {
          "failure": "Refresh error",
          "pending": "Refresh pending",
          "refresh": "Refreshing object from remote server…",
          "saveFailure": "Error while saving settings",
          "skipped": "Refresh was skipped",
          "success": "Refresh successful"
        },
        "loader": {
          "awaitingResult": "Waiting for result…",
          "fetchRequest": "Requesting a fetch…"
        },
        "table": {
          "error": {
            "label": {
              "detail": "Error detail",
              "type": "Error type"
            },
            "value": {
              "connectionError": "Impossible to connect to the remote server",
              "httpError": "An HTTP error occurred while contacting the remote server",
              "httpStatus": "The remote server answered with HTTP {status}",
              "invalidAttributesError": "Data returned by the remote server had invalid or missing attributes",
              "invalidJsonError": "The remote server returned invalid JSON or JSON-LD data",
              "timeoutError": "The remote server didn't respond quickly enough",
              "unknownError": "Unknown error"
            }
          }
        }
      },
      "LibraryWidget": {
        "button": {
          "showMore": "Show more"
        },
        "empty": {
          "noMatch": "No matching library."
        }
      }
    },
    "forms": {
      "PasswordInput": {
        "button": {
          "copy": "Copy"
        },
        "message": {
          "copy": "Text copied to clipboard!"
        },
        "title": "Show/hide password"
      }
    },
    "library": {
      "AlbumBase": {
        "link": {
          "addDescription": "Add a description…"
        },
        "meta": {
          "episodes": "No episodes | {n} episode | {n} episodes",
          "tracks": "No tracks | {n} track | {n} tracks"
        },
        "title": "Album"
      },
      "AlbumDetail": {
        "description": {
          "libraries": "This album is present in the following libraries:"
        },
        "header": {
          "episodes": "Episodes",
          "libraries": "User libraries",
          "tracks": "Tracks"
        },
        "meta": {
          "volume": "Volume {number}"
        }
      },
      "AlbumDropdown": {
        "button": {
          "cancel": "Cancel",
          "delete": "Delete",
          "edit": "Edit",
          "embed": "Embed",
          "more": "More…"
        },
        "link": {
          "discogs": "Search on Discogs",
          "django": "View in Django's admin",
          "domain": "View on {domain}",
          "moderation": "Open in moderation interface",
          "musicbrainz": "View on MusicBrainz"
        },
        "modal": {
          "delete": {
            "content": {
              "warning": "The album will be deleted, as well as any related files and data. This action is irreversible."
            },
            "header": "Delete this album?"
          },
          "embed": {
            "header": "Embed this album on your website"
          }
        }
      },
      "AlbumEdit": {
        "header": {
          "edit": "Edit this album",
          "suggest": "Suggest an edit on this album"
        },
        "message": {
          "remote": "This object is managed by another server, you cannot edit it."
        }
      },
      "Albums": {
        "button": {
          "search": "Search"
        },
        "empty": {
          "noResults": "No results matching your query"
        },
        "header": {
          "browse": "Browsing albums"
        },
        "label": {
          "search": "Search",
          "tags": "Tags"
        },
        "link": {
          "addMusic": "Add some music"
        },
        "ordering": {
          "direction": {
            "ascending": "Ascending",
            "descending": "Descending",
            "label": "Ordering direction"
          },
          "label": "Ordering"
        },
        "pagination": {
          "results": "Results per page"
        },
        "placeholder": {
          "search": "Enter album title…"
        },
        "title": "Albums"
      },
      "ArtistBase": {
        "button": {
          "cancel": "Cancel",
          "edit": "Edit",
          "embed": "Embed",
          "more": "More…",
          "play": "Play all albums"
        },
        "link": {
          "discogs": "Search on Discogs",
          "django": "View in Django's admin",
          "domain": "View on {domain}",
          "moderation": "Open in moderation interface",
          "musicbrainz": "View on MusicBrainz",
          "wikipedia": "Search on Wikipedia"
        },
        "meta": {
          "albums": "0 albums | {n} album | {n} albums",
          "tracks": "0 tracks in | {count} track in | {count} tracks in"
        },
        "modal": {
          "embed": {
            "header": "Embed this artist work on your website"
          }
        },
        "title": "Artist"
      },
      "ArtistDetail": {
        "button": {
          "filter": "Remove filter",
          "more": "Load more…"
        },
        "description": {
          "library": "This artist is present in the following libraries:"
        },
        "header": {
          "album": "Albums by this artist",
          "library": "User libraries",
          "track": "New tracks by this artist"
        },
        "link": {
          "filter": "Review my filters"
        },
        "message": {
          "filter": "You are currently hiding content related to this artist."
        }
      },
      "ArtistEdit": {
        "header": {
          "edit": "Edit this artist",
          "suggest": "Suggest an edit on this artist"
        },
        "message": {
          "remote": "This object is managed by another server, you cannot edit it."
        }
      },
      "Artists": {
        "button": {
          "search": "Search",
          "upload": "Add some music"
        },
        "empty": {
          "noResults": "No results matching your query"
        },
        "header": {
          "browse": "Browsing artists"
        },
        "label": {
          "excludeCompilation": "Exclude Compilation Artists",
          "search": "Artist name",
          "tags": "Tags"
        },
        "ordering": {
          "direction": {
            "ascending": "Ascending",
            "descending": "Descending",
            "label": "Ordering direction"
          },
          "label": "Ordering"
        },
        "pagination": {
          "results": "Results per page"
        },
        "placeholder": {
          "search": "Search…"
        },
        "title": "Artists"
      },
      "EditCard": {
        "button": {
          "approve": "Approve",
          "delete": "Delete",
          "reject": "Reject"
        },
        "header": {
          "modification": "Modification {id}"
        },
        "link": {
          "track": "Track #{id} - {name}"
        },
        "modal": {
          "content": {
            "warning": "The suggestion will be completely removed, this action is irreversible."
          },
          "delete": {
            "header": "Delete this suggestion?"
          }
        },
        "status": {
          "applied": "Approved and applied",
          "approved": "Approved",
          "pending": "Pending",
          "rejected": "Rejected"
        },
        "table": {
          "update": {
            "header": {
              "field": "Field",
              "newValue": "New value",
              "oldValue": "Old value"
            },
            "notApplicable": "N/A"
          }
        }
      },
      "EditForm": {
        "button": {
          "cancel": "Cancel",
          "clear": "Clear",
          "new": "Submit another edit",
          "reset": "Reset to initial value",
          "showAll": "Show all edits",
          "showUnreviewed": "Restrict to unreviewed edits",
          "submit": "Submit and apply edit",
          "suggest": "Submit suggestion"
        },
        "empty": {
          "suggestEdit": "Suggest a change using the form below"
        },
        "header": {
          "failure": "Error while submitting edit",
          "recentEdits": "Recent edits",
          "success": "Your edit was successfully submitted.",
          "unreviewed": "Restrict to unreviewed edits"
        },
        "label": {
          "summary": "Summary (optional)"
        },
        "message": {
          "noPermission": "You don't have the permission to edit this object, but you can suggest changes. Once submitted, suggestions will be reviewed before approval."
        },
        "notApplicable": "N/A",
        "placeholder": {
          "summary": "A short summary describing your changes."
        }
      },
      "FileUpload": {
        "button": {
          "cancel": "Cancel",
          "retry": "Retry failed uploads"
        },
        "description": {
          "import": "Import status",
          "previousImport": "Results of your previous import:"
        },
        "empty": {
          "noFiles": "0"
        },
        "header": {
          "failure": "Error while launching import",
          "local": "Upload music from '~/your local storage",
          "server": "Import music from your server",
          "status": "Import status"
        },
        "label": {
          "extensions": "Supported extensions: {extensions}",
          "remainingSpace": "Remaining storage space",
          "uploadWidget": "Click to select files to upload or drag and drop files or directories"
        },
        "link": {
          "picard": "We recommend using Picard for that purpose.",
          "processing": "Processing",
          "uploading": "Uploading"
        },
        "message": {
          "listener": "This page is asking you to confirm that you want to leave - data you have entered may not be saved.",
          "local": {
            "copyright": "You are not uploading copyrighted content in a public library, otherwise you may be infringing the law",
            "format": "The music files you are uploading are in OGG, Flac, MP3 or AIFF format",
            "message": "You are about to upload music to your library. Before proceeding, please ensure that:",
            "tag": "The music files you are uploading are tagged properly."
          }
        },
        "table": {
          "upload": {
            "header": {
              "actions": "Actions",
              "filename": "Filename",
              "size": "Size",
              "status": "Status"
            },
            "progress": "{percent}%",
            "status": {
              "pending": "Pending",
              "uploaded": "Uploaded",
              "uploading": "Uploading…"
            }
          }
        },
        "tooltip": {
          "denied": "Upload denied, ensure the file is not too big and that you have not reached your quota",
          "extension": "Invalid file type, ensure you are uploading an audio file. Supported file extensions are {extensions}",
          "network": "A network error occurred while uploading this file",
          "retry": "Retry",
          "size": "Cannot upload this file, ensure it is not too big",
          "timeout": "Upload timeout, please try again"
        }
      },
      "FsBrowser": {
        "button": {
          "import": "Import"
        }
      },
      "FsLogs": {
        "empty": {
          "notStarted": "Import hasn't started yet"
        }
      },
      "Home": {
        "header": {
          "newChannels": "New channels",
          "playlists": "Playlists",
          "recentlyAdded": "Recently added",
          "recentlyFavorited": "Recently favorited",
          "recentlyListened": "Recently listened"
        },
        "title": "Library"
      },
      "ImportStatusModal": {
        "button": {
          "close": "Close"
        },
        "error": {
          "importFailure": "An error occurred during upload processing. You will find more information below.",
          "invalidMetadata": {
            "label": "Invalid metadata",
            "message": "The metadata included in the file is invalid or some mandatory fields are missing."
          },
          "unknownError": {
            "label": "Unknown error",
            "message": "An unknown error occurred"
          }
        },
        "header": {
          "importDetail": "Import detail"
        },
        "link": {
          "documentation": "Read our documentation for this error",
          "support": "Open a support thread (include the debug information below in your message)"
        },
        "message": {
          "importDetail": "Upload is still pending and will soon be processed by the server.",
          "importSuccess": "Upload was successfully processed by the server."
        },
        "table": {
          "error": {
            "debug": "Debug information",
            "errorDetail": "Error detail",
            "errorType": "Error type",
            "help": "Getting help"
          }
        },
        "warning": {
          "importSkipped": "Upload was skipped because a similar one is already available in one of your libraries."
        }
      },
      "Podcasts": {
        "button": {
          "cancel": "Cancel",
          "channel": "Create a Channel",
          "feed": "Subscribe to feed",
          "search": "Search",
          "subscribe": "Subscribe"
        },
        "empty": {
          "noResults": "No results matching your query"
        },
        "header": {
          "browse": "Browsing podcasts"
        },
        "label": {
          "search": "Podcast title",
          "tags": "Tags"
        },
        "modal": {
          "subscription": {
            "header": "Subscription"
          }
        },
        "ordering": {
          "direction": {
            "ascending": "Ascending",
            "descending": "Descending",
            "label": "Ordering direction"
          },
          "label": "Ordering"
        },
        "pagination": {
          "results": "Results per page"
        },
        "placeholder": {
          "search": "Search…"
        },
        "title": "Podcasts"
      },
      "Radios": {
        "button": {
          "add": "Create a radio",
          "create": "Create your own radio",
          "search": "Search"
        },
        "empty": {
          "noResults": "No results matching your query"
        },
        "header": {
          "browse": "Browsing radios",
          "instance": "Instance radios",
          "user": "User radios"
        },
        "label": {
          "search": "Search"
        },
        "ordering": {
          "direction": {
            "ascending": "Ascending",
            "descending": "Descending",
            "label": "Order"
          },
          "label": "Ordering"
        },
        "pagination": {
          "results": "Results per page"
        },
        "placeholder": {
          "search": "Enter a radio name…"
        },
        "title": "Radios"
      },
      "TagDetail": {
        "header": {
          "channels": "Channels",
          "tracks": "Tracks"
        },
        "link": {
          "albums": "Albums",
          "artists": "Artists",
          "moderation": "Open in moderation interface"
        }
      },
      "TagSelector": {
        "placeholder": {
          "search": "Search…"
        }
      },
      "TrackBase": {
        "button": {
          "cancel": "Cancel",
          "delete": "Delete",
          "download": "Download",
          "edit": "Edit",
          "embed": "Embed",
          "more": "More…",
          "play": "Play"
        },
        "link": {
          "discogs": "Search on Discogs",
          "django": "View in Django's admin",
          "domain": "View on {domain}",
          "moderation": "Open in moderation interface",
          "wikipedia": "Search on Wikipedia"
        },
        "modal": {
          "delete": {
            "content": {
              "warning": "The track will be deleted, as well as any related files and data. This action is irreversible."
            },
            "header": "Delete this track?"
          },
          "embed": {
            "header": "Embed this track on your website"
          }
        },
        "subtitle": {
          "with-uploader": "Uploaded by {0} on {1}",
          "without-uploader": "Uploaded on {0}"
        },
        "title": "Track"
      },
      "TrackDetail": {
        "description": {
          "library": "This track is present in the following libraries:"
        },
        "header": {
          "episode": "Episode details",
          "library": "Related libraries",
          "playlists": "Related Playlists",
          "release": "Release Details",
          "track": "Track details"
        },
        "link": {
          "musicbrainz": "View on MusicBrainz"
        },
        "notApplicable": "N/A",
        "table": {
          "release": {
            "album": "Album",
            "artist": "Artist",
            "copyright": "Copyright",
            "license": "License",
            "series": "Serie",
            "url": "URL",
            "year": "Year"
          },
          "track": {
            "bitrate": {
              "label": "Bitrate",
              "value": "{bitrate}/s"
            },
            "codec": "Codec",
            "downloads": "Downloads",
            "duration": "Duration",
            "size": "Size"
          }
        }
      },
      "TrackEdit": {
        "header": {
          "edit": "Edit this track",
          "suggest": "Suggest an edit on this track"
        },
        "message": {
          "remote": "This object is managed by another server, you cannot edit it."
        }
      },
      "radios": {
        "Builder": {
          "button": {
            "filter": "Add filter",
            "save": "Save"
          },
          "description": {
            "builder": "You can use this interface to build your own custom radio, which will play tracks according to your criteria."
          },
          "header": {
            "builder": "Builder",
            "created": "Radio created",
            "matches": "0 tracks matching combined filters | {n} track matching combined filters | {n} tracks matching combined filters",
            "updated": "Radio updated"
          },
          "label": {
            "description": "Description",
            "filter": "Add filters to customize your radio",
            "name": "Radio name",
            "public": "Display publicly"
          },
          "option": {
            "filter": "Select a filter"
          },
          "placeholder": {
            "description": "My awesome description",
            "name": "My awesome radio"
          },
          "table": {
            "filter": {
              "header": {
                "actions": "Actions",
                "candidates": "Candidates",
                "config": "Config",
                "exclude": "Exclude",
                "name": "Filter name"
              }
            }
          },
          "title": "Radio Builder"
        },
        "Filter": {
          "cancelButton": "Cancel",
          "excludeLabel": "Exclude",
          "matchingTracks": "0 tracks matching filter | {n} track matching filter | {n} tracks matching filter",
          "matchingTracksModalHeader": "Tracks matching filter",
          "removeButton": "Remove"
        }
      }
    },
    "manage": {
      "ChannelsTable": {
        "label": {
          "category": "Category",
          "search": "Search"
        },
        "link": {
          "local": "Local",
          "moderation": "Open in moderation interface"
        },
        "option": {
          "all": "All"
        },
        "ordering": {
          "direction": {
            "ascending": "Ascending",
            "descending": "Descending",
            "label": "Ordering direction"
          },
          "label": "Ordering"
        },
        "pagination": {
          "results": "Showing results {start}-{end} on {total}"
        },
        "placeholder": {
          "search": "Search by domain, name, account…"
        },
        "table": {
          "channel": {
            "header": {
              "account": "Account",
              "albums": "Albums",
              "creationDate": "Creation date",
              "domain": "Domain",
              "name": "Name",
              "tracks": "Tracks"
            }
          }
        }
      },
      "library": {
        "AlbumsTable": {
          "action": {
            "delete": {
              "label": "Delete",
              "warning": "The selected albums will be removed, as well as associated tracks, uploads, favorites and listening history. This action is irreversible."
            }
          },
          "label": {
            "search": "Search"
          },
          "link": {
            "local": "Local",
            "moderation": "Open in moderation interface"
          },
          "notApplicable": "N/A",
          "ordering": {
            "direction": {
              "ascending": "Ascending",
              "descending": "Descending",
              "label": "Ordering direction"
            },
            "label": "Ordering"
          },
          "pagination": {
            "results": "Showing results {start}-{end} on {total}"
          },
          "placeholder": {
            "search": "Search by domain, title, artist, MusicBrainz ID…"
          },
          "table": {
            "album": {
              "header": {
                "artist": "Artist",
                "creationDate": "Creation date",
                "domain": "Domain",
                "name": "Name",
                "releaseDate": "Release date",
                "tracks": "Tracks"
              }
            }
          }
        },
        "ArtistsTable": {
          "action": {
            "delete": {
              "label": "Delete",
              "warning": "The selected artist will be removed, as well as associated tracks, uploads, favorites and listening history. This action is irreversible."
            }
          },
          "label": {
            "category": "Category",
            "search": "Search"
          },
          "link": {
            "local": "Local"
          },
          "option": {
            "all": "All"
          },
          "ordering": {
            "direction": {
              "ascending": "Ascending",
              "descending": "Descending",
              "label": "Ordering direction"
            },
            "label": "Ordering"
          },
          "pagination": {
            "results": "Showing results {start}-{end} on {total}"
          },
          "placeholder": {
            "search": "Search by domain, name, MusicBrainz ID…"
          },
          "table": {
            "artist": {
              "header": {
                "albums": "Albums",
                "creationDate": "Creation date",
                "domain": "Domain",
                "name": "Name",
                "tracks": "Tracks"
              }
            }
          }
        },
        "EditsCardList": {
          "label": {
            "search": "Search",
            "status": "Status"
          },
          "option": {
            "all": "All",
            "approved": "Approved",
            "pending": "Pending review",
            "rejected": "Rejected"
          },
          "ordering": {
            "direction": {
              "ascending": "Ascending",
              "descending": "Descending",
              "label": "Order"
            },
            "label": "Ordering"
          },
          "pagination": {
            "results": "Showing results {start}-{end} on {total}"
          },
          "placeholder": {
            "search": "Search by account, summary, domain…"
          }
        },
        "LibrariesTable": {
          "action": {
            "delete": {
              "label": "Delete",
              "warning": "The selected library will be removed, as well as associated uploads and follows. This action is irreversible."
            }
          },
          "label": {
            "search": "Search",
            "visibility": "Visibility"
          },
          "link": {
            "local": "Local"
          },
          "option": {
            "all": "All"
          },
          "ordering": {
            "direction": {
              "ascending": "Ascending",
              "descending": "Descending",
              "label": "Ordering direction"
            },
            "label": "Ordering"
          },
          "pagination": {
            "results": "Showing results {start}-{end} on {total}"
          },
          "placeholder": {
            "search": "Search by domain, actor, name, description…"
          },
          "table": {
            "library": {
              "header": {
                "account": "Account",
                "creationDate": "Creation date",
                "domain": "Domain",
                "followers": "Followers",
                "name": "Name",
                "uploads": "Uploads",
                "visibility": "Visibility"
              }
            }
          }
        },
        "TagsTable": {
          "action": {
            "delete": {
              "label": "Delete",
              "warning": "The selected tag will be removed and unlinked with existing content, if any. This action is irreversible."
            }
          },
          "label": {
            "search": "Search"
          },
          "ordering": {
            "direction": {
              "ascending": "Ascending",
              "descending": "Descending",
              "label": "Ordering direction"
            },
            "label": "Ordering"
          },
          "pagination": {
            "results": "Showing results {start}-{end} on {total}"
          },
          "placeholder": {
            "search": "Search by name"
          },
          "table": {
            "tag": {
              "header": {
                "albums": "Albums",
                "artists": "Artists",
                "creationDate": "Creation date",
                "name": "Name",
                "tracks": "Tracks"
              }
            }
          }
        },
        "TracksTable": {
          "action": {
            "delete": {
              "label": "Delete",
              "warning": "The selected tracks will be removed, as well as associated uploads, favorites and listening history. This action is irreversible."
            }
          },
          "label": {
            "search": "Search"
          },
          "link": {
            "local": "Local"
          },
          "notApplicable": "N/A",
          "ordering": {
            "direction": {
              "ascending": "Ascending",
              "descending": "Descending",
              "label": "Ordering direction"
            },
            "label": "Ordering"
          },
          "pagination": {
            "results": "Showing results {start}-{end} on {total}"
          },
          "placeholder": {
            "search": "Search by domain, title, artist, album, MusicBrainz ID…"
          },
          "table": {
            "track": {
              "header": {
                "album": "Album",
                "artist": "Artist",
                "creationDate": "Creation date",
                "domain": "Domain",
                "license": "License",
                "title": "Title"
              }
            }
          }
        },
        "UploadsTable": {
          "action": {
            "delete": {
              "label": "Delete",
              "warning": "The selected upload will be removed. This action is irreversible."
            }
          },
          "label": {
            "search": "Search",
            "status": "Import status",
            "visibility": "Visibility"
          },
          "link": {
            "local": "Local"
          },
          "notApplicable": "N/A",
          "option": {
            "all": "All",
            "failed": "Failed",
            "finished": "Finished",
            "pending": "Pending",
            "skipped": "Skipped"
          },
          "ordering": {
            "direction": {
              "ascending": "Ascending",
              "descending": "Descending",
              "label": "Ordering direction"
            },
            "label": "Ordering"
          },
          "pagination": {
            "results": "Showing results {start}-{end} on {total}"
          },
          "placeholder": {
            "search": "Search by domain, actor, name, reference, source…"
          },
          "table": {
            "upload": {
              "header": {
                "accessedDate": "Accessed date",
                "account": "Account",
                "creationDate": "Creation date",
                "domain": "Domain",
                "importStatus": "Import status",
                "library": "Library",
                "name": "Name",
                "size": "Size",
                "visibility": "Visibility"
              }
            }
          }
        }
      },
      "moderation": {
        "AccountsTable": {
          "action": {
            "purge": {
              "label": "Purge"
            }
          },
          "label": {
            "search": "Search"
          },
          "link": {
            "local": "Local account"
          },
          "ordering": {
            "direction": {
              "ascending": "Ascending",
              "descending": "Descending",
              "label": "Ordering direction"
            },
            "label": "Ordering"
          },
          "pagination": {
            "results": "Showing results {start}-{end} on {total}"
          },
          "placeholder": {
            "search": "Search by domain, username, bio…"
          },
          "table": {
            "account": {
              "header": {
                "domain": "Domain",
                "firstSeen": "First seen",
                "lastSeen": "Last seen",
                "moderationRule": "Under moderation rule",
                "name": "Name",
                "uploads": "Uploads"
              },
              "moderationRule": "Yes"
            }
          }
        },
        "DomainsTable": {
          "action": {
            "add": {
              "label": "Add to allow-list"
            },
            "purge": {
              "label": "Purge"
            },
            "remove": {
              "label": "Remove from allow-list"
            }
          },
          "empty": {
            "noPods": "No other pods found"
          },
          "label": {
            "inList": "Is present on allow-list",
            "search": "Search"
          },
          "link": {
            "list": "This domain is present in your allow-list"
          },
          "option": {
            "all": "All",
            "no": "No",
            "yes": "Yes"
          },
          "ordering": {
            "direction": {
              "ascending": "Ascending",
              "descending": "Descending",
              "label": "Ordering direction"
            },
            "label": "Ordering"
          },
          "pagination": {
            "results": "Showing results {start}-{end} on {total}"
          },
          "placeholder": {
            "search": "Search by name…"
          },
          "table": {
            "domain": {
              "header": {
                "firstSeen": "First seen",
                "moderationRule": "Under moderation rule",
                "name": "Name",
                "receivedMessages": "Received messages",
                "users": "Users"
              },
              "moderationRule": "Yes"
            }
          }
        },
        "InstancePolicyCard": {
          "button": {
            "edit": "Edit"
          },
          "header": {
            "rule": "Rule"
          },
          "label": {
            "blockAll": "Block everything",
            "muteActivity": "Mute activity",
            "muteNotifications": "Mute notifications",
            "reason": "Reason",
            "rejectMedia": "Reject media"
          },
          "status": {
            "enabled": "Enabled",
            "paused": "Paused"
          }
        },
        "InstancePolicyForm": {
          "button": {
            "cancel": "Cancel",
            "confirm": "Delete moderation rule",
            "create": "Create",
            "delete": "Delete",
            "update": "Update"
          },
          "header": {
            "addRule": "Add a new moderation rule",
            "editRule": "Edit moderation rule",
            "failure": "Error while creating rule"
          },
          "label": {
            "blockAll": "Block everything",
            "customizeRule": "Or customize your rule",
            "policyDisabled": "Disabled",
            "policyEnabled": "Enabled",
            "policyReason": "Reason",
            "rejectMedia": "Reject media",
            "silenceActivity": "Mute activity",
            "silenceNotifications": "Mute notifications"
          },
          "modal": {
            "delete": {
              "content": {
                "warning": "This action is irreversible"
              },
              "header": "Delete this moderation rule?"
            }
          },
          "tooltip": {
            "blockAll": "Block everything from this account or domain. This will prevent any interaction with the entity, and purge related content (uploads, libraries, follows, etc.)",
            "isActive": "Use this setting to temporarily enable/disable the policy without completely removing it.",
            "rejectMedia": "Hide account or domain content, except from followers.",
            "silenceActivity": "Hide account or domain content, except from followers.",
            "silenceNotifications": "Prevent account or domain from triggering notifications, except from followers.",
            "summary": "Explain why you're applying this policy: this will help you remember why you added this rule. Depending on your pod configuration, this may be displayed publicly to help users understand the moderation rules in place."
          }
        },
        "InstancePolicyModal": {
          "button": {
            "close": "Close",
            "show": "Moderation rules…"
          },
          "modal": {
            "manage": {
              "content": {
                "warning": "This entity is subject to specific moderation rules"
              },
              "header": "Manage moderation rules for {obj}"
            }
          }
        },
        "NoteForm": {
          "button": {
            "add": "Add note"
          },
          "header": {
            "failure": "Error while submitting note"
          },
          "placeholder": {
            "summary": "Describe what actions have been taken, or any other related updates…"
          }
        },
        "NotesThread": {
          "button": {
            "delete": "Delete"
          },
          "modal": {
            "delete": {
              "content": {
                "warning": "The note will be deleted. This action is irreversible."
              },
              "header": "Delete this note?"
            }
          }
        },
        "ReportCard": {
          "button": {
            "confirmDelete": "Delete",
            "delete": "Delete reported object",
            "resolve": "Resolve",
            "unresolve": "Unresolve"
          },
          "header": {
            "actions": "Actions",
            "message": "Message",
            "notes": "Internal notes",
            "reportedObject": "Reported object"
          },
          "link": {
            "moderation": "Open in moderation interface",
            "publicPage": "View public page",
            "report": "Report {id}"
          },
          "modal": {
            "delete": {
              "content": {
                "warning": "This will delete the object associated with this report and mark the report as resolved. The deletion is irreversible."
              },
              "header": "Delete reported object?"
            }
          },
          "notApplicable": "N/A",
          "table": {
            "object": {
              "account": "Account",
              "domain": "Domain",
              "local": "Local",
              "owner": "Owner",
              "type": "Type"
            },
            "report": {
              "category": "Category",
              "creationDate": "Creation date",
              "submittedBy": "Submitted by"
            },
            "status": {
              "assignedTo": "Assigned to",
              "internalNotes": "Internal notes",
              "resolutionDate": "Resolution date",
              "resolved": "Resolved",
              "status": "Status",
              "unresolved": "Unresolved"
            }
          },
          "warning": {
            "objectDeleted": "The object associated with this report was deleted."
          }
        },
        "UserRequestCard": {
          "button": {
            "approve": "Approve",
            "reject": "Refuse"
          },
          "header": {
            "actions": "Actions",
            "notes": "Internal notes",
            "signup": "Message"
          },
          "link": {
            "request": "Request {id}"
          },
          "message": {
            "signup": "This user wants to sign-up on your pod."
          },
          "notApplicable": "N/A",
          "table": {
            "request": {
              "creationDate": "Creation date",
              "submittedBy": "Submitted by"
            },
            "status": {
              "approved": "Approved",
              "assignedTo": "Assigned to",
              "internalNotes": "Internal notes",
              "pending": "Pending",
              "refused": "Refused",
              "resolutionDate": "Resolution date",
              "status": "Status"
            }
          }
        }
      },
      "users": {
        "InvitationForm": {
          "button": {
            "clear": "Clear",
            "new": "Get a new invitation"
          },
          "header": {
            "failure": "Error while creating invitation"
          },
          "label": {
            "invite": "Invitation code"
          },
          "placeholder": {
            "invitation": "Leave empty for a random code"
          },
          "table": {
            "invitation": {
              "header": {
                "code": "Code",
                "link": "Share link"
              }
            }
          }
        },
        "InvitationsTable": {
          "action": {
            "delete": "Delete"
          },
          "label": {
            "expired": "Expired",
            "search": "Search",
            "status": "Status",
            "unused": "Not used",
            "used": "Used"
          },
          "option": {
            "all": "All",
            "expired": "Expired/used",
            "open": "Open"
          },
          "ordering": {
            "label": "Ordering"
          },
          "pagination": {
            "results": "Showing zero results | Showing one result | Showing results {start} to {end} from {total}"
          },
          "placeholder": {
            "search": "Search by username, e-mail address, code…"
          },
          "table": {
            "invitation": {
              "header": {
                "code": "Code",
                "creationDate": "Creation date",
                "expirationDate": "Expiration date",
                "owner": "Owner",
                "status": "Status",
                "user": "User"
              }
            }
          }
        },
        "UsersTable": {
          "label": {
            "search": "Search"
          },
          "notApplicable": "N/A",
          "ordering": {
            "direction": {
              "ascending": "Ascending",
              "descending": "Descending",
              "label": "Ordering direction"
            },
            "label": "Ordering"
          },
          "pagination": {
            "results": "Showing zero results | Showing one result | Showing results {start} to {end} from {total}"
          },
          "permission": {
            "library": "Library",
            "moderation": "Moderation",
            "settings": "Settings"
          },
          "placeholder": {
            "search": "Search by username, e-mail address, name…"
          },
          "table": {
            "user": {
              "accountStatus": {
                "active": "Active",
                "inactive": "Inactive"
              },
              "header": {
                "accountStatus": "Account status",
                "email": "Email",
                "lastActivity": "Last activity",
                "permissions": "Permissions",
                "signup": "Sign-up",
                "status": "Status",
                "username": "Username"
              },
              "status": {
                "admin": "Admin",
                "regular": "Regular user",
                "staff": "Staff member"
              }
            }
          }
        }
      }
    },
    "moderation": {
      "FilterModal": {
        "button": {
          "cancel": "Cancel",
          "hide": "Hide content"
        },
        "header": {
          "failure": "Error while creating filter",
          "modal": "Do you want to hide content from artist \"{name}\"?"
        },
        "help": {
          "createFilter": "You can manage your filters any time from your account settings."
        },
        "message": {
          "success": "Content filter successfully added"
        },
        "warning": {
          "createFilter": {
            "listIntro": "You will not see tracks, albums and user activity linked to this artist any more:",
            "listItem1": "In other users favorites and listening history",
            "listItem2": "In \"Recently added\" widget",
            "listItem3": "In artists and album listings",
            "listItem4": "In radio suggestions"
          }
        }
      },
      "ReportCategoryDropdown": {
        "label": {
          "category": "Category"
        },
        "option": {
          "all": "All"
        }
      },
      "ReportModal": {
        "button": {
          "cancel": "Cancel",
          "submit": "Submit report"
        },
        "description": {
          "email": "We'll use this e-mail address if we need to contact you regarding this report.",
          "forwardToDomain": "Forward an anonymized copy of your report to the server hosting this element.",
          "message": "Use this field to provide additional context to the moderator that will handle your report.",
          "modal": "Use this form to submit a report to our moderation team"
        },
        "error": {
          "nodeinfoFetch": "Cannot fetch Node Info: {error}"
        },
        "header": {
          "disabled": "Anonymous reports are disabled, please sign-in to submit a report.",
          "modal": "Do you want to report this object?",
          "submissionFailure": "Error while submitting report"
        },
        "label": {
          "email": "Email",
          "forwardToDomain": "Forward to {domain}",
          "message": "Message"
        },
        "message": {
          "submissionSuccess": "Report successfully submitted, thank you"
        }
      }
    },
    "notifications": {
      "NotificationRow": {
        "button": {
          "approve": "Approve",
          "markRead": "Mark as read",
          "markUnread": "Mark as unread",
          "reject": "Reject"
        },
        "message": {
          "libraryAcceptFollow": "{username} accepted your follow on library \"{library}\"",
          "libraryFollow": "{username} followed your library \"{library}\"",
          "libraryPendingFollow": "{username} wants to follow your library \"{library}\"",
          "libraryReject": "You rejected {username}'s request to follow \"{library}\""
        }
      }
    },
    "playlists": {
      "Card": {
        "meta": {
          "tracks": "No tracks | {n} track | {n} tracks"
        }
      },
      "Editor": {
        "button": {
          "addDuplicate": "Add anyways",
          "clear": "Clear playlist",
          "copy": "Copy the current queue to this playlist",
          "insertFromQueue": "Insert from queue ({n} track) | Insert from queue ({n} tracks"
        },
        "error": {
          "sync": "An error occurred while saving your changes"
        },
        "header": {
          "editor": "Playlist editor"
        },
        "help": {
          "reorder": "Drag and drop rows to reorder tracks in the playlist"
        },
        "loading": {
          "sync": "Syncing changes to server…"
        },
        "message": {
          "sync": "Changes synced with server"
        },
        "modal": {
          "clearPlaylist": {
            "content": {
              "warning": "This will remove all tracks from this playlist and cannot be undone."
            },
            "header": "Do you want to clear the playlist \"{playlist}\"?"
          }
        },
        "warning": {
          "duplicate": "Some tracks in your queue are already in this playlist:"
        }
      },
      "Form": {
        "button": {
          "create": "Create playlist",
          "update": "Update playlist"
        },
        "header": {
          "createFailure": "The playlist could not be created",
          "createPlaylist": "Create a new playlist",
          "createSuccess": "Playlist created",
          "updateSuccess": "Playlist updated"
        },
        "label": {
          "name": "Playlist name",
          "visibility": "Playlist visiblity"
        },
        "placeholder": {
          "name": "My awesome playlist"
        }
      },
      "PlaylistModal": {
        "button": {
          "addDuplicate": "Add anyways",
          "addToPlaylist": "Add to this playlist",
          "addTrack": "Add track",
          "cancel": "Cancel",
          "edit": "Edit"
        },
        "empty": {
          "noPlaylists": "No playlists have been created yet"
        },
        "header": {
          "addFailure": "The track can't be added to a playlist",
          "addToPlaylist": "Add to playlist",
          "available": "Available playlists",
          "manage": "Manage playlists",
          "noResults": "No results matching your filter",
          "track": "{title}, by {artist}"
        },
        "label": {
          "filter": "Filter"
        },
        "placeholder": {
          "filterPlaylist": "Enter playlist name"
        },
        "table": {
          "edit": {
            "header": {
              "edit": "Edit",
              "lastModification": "Last modification",
              "name": "Name",
              "tracks": "Tracks"
            }
          }
        },
        "warning": {
          "duplicate": "{ 0 } is already in { 1 }."
        }
      },
      "TrackPlaylistIcon": {
        "button": {
          "add": "Add to playlist…"
        }
      },
      "Widget": {
        "button": {
          "create": "Create playlist",
          "more": "Show more"
        },
        "placeholder": {
          "noPlaylists": "No playlists have been created yet"
        }
      }
    },
    "radios": {
      "Button": {
        "startArtistsRadio": "Start artists radio",
        "startPlaylistsRadio": "Start playlists radio",
        "startRadio": "Play radio",
        "startTagsRadio": "Start tags radio",
        "stopArtistsRadio": "Stop artists radio",
        "stopPlaylistsRadio": "Stop playlists radio",
        "stopRadio": "Stop radio",
        "stopTagsRadio": "Stop tags radio"
      },
      "Card": {
        "button": {
          "edit": "Edit"
        }
      }
    },
    "tags": {
      "List": {
        "button": {
          "more": "Show 1 more tag | Show {n} more tags"
        }
      }
    },
    "vui": {
      "Pagination": {
        "label": "Pagination",
        "next": "Next Page",
        "previous": "Previous Page"
      }
    }
  },
  "composables": {
    "audio": {
      "usePlayOptions": {
        "addToQueueMessage": "{n} tracks were added to your queue | {n} track was added to your queue | {n} tracks were added to your queue"
      }
    },
    "locale": {
      "useSharedLabels": {
        "fields": {
          "contentCategory": {
            "choices": {
              "music": "Music",
              "other": "Other",
              "podcast": "Podcast"
            },
            "label": "Content category"
          },
          "importStatus": {
            "choices": {
              "draft": {
                "help": "This track has been uploaded, but hasn't been scheduled for processing yet",
                "label": "Draft"
              },
              "errored": {
                "help": "This track could not be processed, please make sure it is tagged correctly",
                "label": "Errored"
              },
              "finished": {
                "help": "Imported",
                "label": "Finished"
              },
              "pending": {
                "help": "This track has been uploaded, but hasn't been processed by the server yet",
                "label": "Pending"
              },
              "skipped": {
                "help": "This track is already present in one of your libraries",
                "label": "Skipped"
              }
            },
            "label": "Click to display more information about the import process for this upload"
          },
          "privacyLevel": {
            "choices": {
              "instance": "Everyone on this instance",
              "private": "Nobody except me",
              "public": "Everyone, across all instances"
            },
            "help": "Determine the visiblity level of your activity",
            "label": "Activity visibility",
            "shortChoices": {
              "instance": "Instance",
              "private": "Private",
              "public": "Everyone"
            }
          },
          "reportType": {
            "choices": {
              "illegalContent": "Illegal content",
              "invalidMetadata": "Invalid metadata",
              "offensiveContent": "Offensive content",
              "other": "Other",
              "takedownRequest": "Takedown request"
            },
            "label": "Category"
          },
          "summary": {
            "label": "Bio"
          }
        },
        "filters": {
          "accessedDate": "Accessed date",
          "albumTitle": "Album name",
          "appliedDate": "Applied date",
          "artistName": "Artist name",
          "bitrate": "Bitrate",
          "creationDate": "Creation date",
          "dateJoined": "Sign-up date",
          "domain": "Domain",
          "duration": "Duration",
          "expirationDate": "Expiration date",
          "firstSeen": "First seen date",
          "followers": "Followers",
          "handledDate": "Handled date",
          "itemsCount": "Items",
          "lastActivity": "Last activity",
          "lastSeen": "Last seen date",
          "modificationDate": "Modification date",
          "name": "Name",
          "receivedMessages": "Received messages",
          "releaseDate": "Release date",
          "size": "Size",
          "trackTitle": "Track name",
          "uploads": "Uploads",
          "username": "Username",
          "users": "Users"
        },
        "scopes": {
          "edits": {
            "description": "Access to edits",
            "label": "Edits"
          },
          "favorites": {
            "description": "Access to favorites",
            "label": "Favorites"
          },
          "filters": {
            "description": "Access to content filters",
            "label": "Content filters"
          },
          "follows": {
            "description": "Access to follows",
            "label": "Follows"
          },
          "libraries": {
            "description": "Access to audio files, libraries, artists, albums and tracks",
            "label": "Libraries and uploads"
          },
          "listenings": {
            "description": "Access to listening history",
            "label": "Listenings"
          },
          "notifications": {
            "description": "Access to notifications",
            "label": "Notifications"
          },
          "playlists": {
            "description": "Access to playlists",
            "label": "Playlists"
          },
          "profile": {
            "description": "Access to e-mail, username, and profile information",
            "label": "Profile"
          },
          "radios": {
            "description": "Access to radios",
            "label": "Radios"
          },
          "reports": {
            "description": "Access to moderation reports",
            "label": "Reports"
          },
          "security": {
            "description": "Access to security settings such as password and authorization",
            "label": "Security"
          }
        }
      }
    },
    "moderation": {
      "useEditConfigs": {
        "album": {
          "releaseDate": "Release date",
          "title": "Title"
        },
        "artist": {
          "name": "Name"
        },
        "cover": {
          "label": "Cover"
        },
        "description": {
          "label": "Description"
        },
        "tags": {
          "label": "Tags"
        },
        "track": {
          "copyright": "Copyright",
          "license": "Licence",
          "position": "Position",
          "title": "Title"
        }
      },
      "useReport": {
        "account": {
          "label": "Report {'@'}{username}",
          "typeLabel": "Account"
        },
        "album": {
          "label": "Report this album…",
          "typeLabel": "Album"
        },
        "artist": {
          "label": "Report this artist…",
          "typeLabel": "Artist",
          "unknownLabel": "Unknown artist"
        },
        "channel": {
          "label": "Report this channel…",
          "typeLabel": "Channel"
        },
        "library": {
          "label": "Report this library…",
          "typeLabel": "Library"
        },
        "playlist": {
          "label": "Report this playlist…",
          "typeLabel": "Playlist"
        },
        "track": {
          "label": "Report this track…",
          "typeLabel": "Track"
        }
      },
      "useReportConfigs": {
        "account": {
          "label": "Account",
          "summary": "Bio"
        },
        "album": {
          "label": "Album",
          "releaseDate": "Release date",
          "title": "Title"
        },
        "artist": {
          "label": "Artist"
        },
        "channel": {
          "label": "Channel"
        },
        "creationDate": {
          "label": "Creation date"
        },
        "library": {
          "description": "Description",
          "label": "Library"
        },
        "musicbrainzId": {
          "label": "MusicBrainz ID"
        },
        "name": {
          "label": "Name"
        },
        "playlist": {
          "label": "Playlist"
        },
        "tags": {
          "label": "Tags"
        },
        "track": {
          "copyright": "Copyright",
          "label": "Track",
          "license": "Licence",
          "position": "Position",
          "title": "Title"
        },
        "visibility": {
          "label": "Visibility"
        }
      }
    },
    "useErrorHandler": {
      "errorReportMessage": "To help us understand why it happened, please attach a detailed description of what you did that has triggered the error.",
      "errorReportTitle": "An unexpected error occured.",
      "leaveFeedback": "Leave feedback",
      "unexpectedError": "An unexpected error occurred."
    },
    "useThemeList": {
      "browserDefault": "Browser default",
      "darkTheme": "Dark",
      "lightTheme": "Light"
    }
  },
  "init": {
    "axios": {
      "rateLimitDelay": "You sent too many requests and have been rate limited, please try again in {delay}",
      "rateLimitLater": "You sent too many requests and have been rate limited, please try again later"
    },
    "sentry": {
      "allow": "Allow",
      "deny": "Deny",
      "funkwhaleInstance": "Funkwhale's official Glitchtip instance",
      "message": "The stack traces will be shared to { 0 } in order to help us understand how and when the errors occur.",
      "title": "To enhance the quality of our services, we would like to collect information about crashes during your session."
    },
    "serviceWorker": {
      "actions": {
        "later": "Later",
        "update": "Update"
      },
      "newAppVersion": "A new version of the app is available."
    }
  },
  "views": {
    "Notifications": {
      "button": {
        "read": "Mark all as read",
        "submit": "Got it!"
      },
      "empty": {
        "notifications": "No notification to show"
      },
      "header": {
        "funkwhaleSupport": "Do you like Funkwhale?",
        "instanceSupport": "Support this Funkwhale pod",
        "messages": "Your messages",
        "notifications": "Your notifications"
      },
      "label": {
        "reminder": "Remind me in:",
        "showRead": "Show read notifications"
      },
      "link": {
        "donate": "Donate",
        "help": "Discover other ways to help"
      },
      "loading": {
        "notifications": "Loading notifications…"
      },
      "message": {
        "funkwhaleSupport": "We noticed you've been here for a while. If Funkwhale is useful to you, we could use your help to make it even better!"
      },
      "option": {
        "delay": {
          "30": "30 days",
          "60": "60 days",
          "90": "90 days",
          "never": "Never"
        }
      },
      "title": "Notifications"
    },
    "Search": {
      "button": {
        "submit": "Submit Search Query"
      },
      "header": {
        "remote": "Search a remote object",
        "rss": "Subscribe to a podcast RSS feed",
        "search": "Search"
      },
      "label": {
        "albums": "Albums",
        "artists": "Artists",
        "playlists": "Playlists",
        "podcasts": "Podcasts",
        "radios": "Radios",
        "series": "Series",
        "tags": "Tags",
        "tracks": "Tracks"
      }
    },
    "admin": {
      "ChannelDetail": {
        "button": {
          "delete": "Delete",
          "openRemote": "Open remote profile",
          "refresh": "Refresh from remote server"
        },
        "header": {
          "activity": "Activity",
          "audioContent": "Audio content",
          "channelData": "Channel data"
        },
        "label": {
          "local": "Local"
        },
        "link": {
          "django": "View in Django's admin",
          "localProfile": "Open local profile"
        },
        "modal": {
          "delete": {
            "content": {
              "warning": "The channel will be removed, as well as associated uploads, tracks, and albums. This action is irreversible."
            },
            "header": "Delete this channel?"
          }
        },
        "table": {
          "activity": {
            "edits": "Edits",
            "favorited": "Favorited tracks",
            "firstSeen": "First seen",
            "linkedReports": "Linked reports",
            "listenings": "Listenings",
            "playlists": "Playlists"
          },
          "audioContent": {
            "albums": "Albums",
            "cachedSize": "Cached size",
            "totalSize": "Total size",
            "tracks": "Tracks",
            "uploads": "Uploads"
          },
          "channelData": {
            "account": "Account",
            "category": "Category",
            "description": "Description",
            "domain": "Domain",
            "name": "Name",
            "rss": "RSS feed",
            "url": "URL"
          }
        },
        "warning": {
          "stats": "Statistics are computed from known activity and content on your instance, and do not reflect general activity for this object"
        }
      },
      "CommonList": {
        "title": {
          "accounts": "Accounts",
          "albums": "Albums",
          "artists": "Artists",
          "channels": "Channels",
          "invitations": "Invitations",
          "libraries": "Libraries",
          "tags": "Tags",
          "tracks": "Tracks",
          "uploads": "Uploads",
          "users": "Users"
        }
      },
      "Settings": {
        "header": {
          "channels": "Channels",
          "federation": "Federation",
          "instanceInfo": "Instance Information",
          "moderation": "Moderation",
          "music": "Music",
          "playlists": "Playlists",
          "sections": "Sections",
          "security": "Security",
          "settings": "Instance Settings",
          "signups": "Sign-ups",
          "stats": "Statistics",
          "subsonic": "Subsonic",
          "ui": "User Interface"
        }
      },
      "library": {
        "AlbumDetail": {
          "button": {
            "delete": "Delete",
            "edit": "Edit",
            "remoteRefresh": "Refresh from remote server"
          },
          "header": {
            "activity": "Activity",
            "albumData": "Album data",
            "audioContent": "Audio content",
            "local": "Local"
          },
          "link": {
            "artist": "Artist",
            "django": "View in Django's admin",
            "domain": "Domain",
            "edits": "Edits",
            "libraries": "Libraries",
            "localProfile": "Open local profile",
            "musicbrainz": "Open on MusicBrainz",
            "remoteProfile": "Open remote profile",
            "reports": "Linked reports",
            "tracks": "Tracks",
            "uploads": "Uploads"
          },
          "modal": {
            "delete": {
              "content": {
                "warning": "The album will be deleted, as well as associated uploads, tracks, favorites and listening history. This action is irreversible."
              },
              "header": "Delete this album?"
            }
          },
          "table": {
            "activity": {
              "favorited": "Favorited tracks",
              "firstSeen": "First seen",
              "listenings": "Listenings",
              "playlists": "Playlists"
            },
            "album": {
              "description": "Description",
              "title": "Title"
            },
            "audioContent": {
              "cachedSize": "Cached size",
              "totalSize": "Total size"
            }
          },
          "warning": {
            "stats": "Statistics are computed from known activity and content on your instance, and do not reflect general activity for this object"
          }
        },
        "ArtistDetail": {
          "button": {
            "delete": "Delete",
            "edit": "Edit",
            "remoteRefresh": "Refresh from remote server"
          },
          "header": {
            "activity": "Activity",
            "artistData": "Artist data",
            "audioContent": "Audio content",
            "local": "Local"
          },
          "link": {
            "albums": "Albums",
            "category": "Category",
            "django": "View in Django's admin",
            "domain": "Domain",
            "edits": "Edits",
            "libraries": "Libraries",
            "localProfile": "Open local profile",
            "musicbrainz": "Open on MusicBrainz",
            "remoteProfile": "Open remote profile",
            "reports": "Linked reports",
            "tracks": "Tracks",
            "uploads": "Uploads"
          },
          "modal": {
            "delete": {
              "content": {
                "warning": "The artist will be deleted, as well as associated uploads, tracks, favorites and listening history. This action is irreversible."
              },
              "header": "Delete this artist?"
            }
          },
          "table": {
            "activity": {
              "favorited": "Favorited tracks",
              "firstSeen": "First seen",
              "listenings": "Listenings",
              "playlists": "Playlists"
            },
            "artist": {
              "description": "Description",
              "name": "Name"
            },
            "audioContent": {
              "cachedSize": "Cached size",
              "totalSize": "Total size"
            }
          },
          "warning": {
            "stats": "Statistics are computed from known activity and content on your instance, and do not reflect general activity for this object"
          }
        },
        "Base": {
          "link": {
            "albums": "Albums",
            "artists": "Artists",
            "channels": "Channels",
            "edits": "Edits",
            "libraries": "Libraries",
            "tags": "Tags",
            "tracks": "Tracks",
            "uploads": "Uploads"
          },
          "menu": {
            "secondary": "Secondary menu"
          },
          "title": "Manage Library"
        },
        "EditsList": {
          "header": {
            "edits": "Library edits"
          },
          "title": "Edits"
        },
        "LibraryDetail": {
          "button": {
            "delete": "Delete"
          },
          "header": {
            "activity": "Activity",
            "audioContent": "Audio content",
            "libraryData": "Library data",
            "local": "Local"
          },
          "link": {
            "account": "Account",
            "albums": "Albums",
            "artists": "Artists",
            "django": "View in Django's admin",
            "domain": "Domain",
            "remoteProfile": "Open remote profile",
            "reports": "Linked reports",
            "tracks": "Tracks",
            "uploads": "Uploads",
            "visibility": "Visibility"
          },
          "modal": {
            "delete": {
              "content": {
                "warning": "The library will be deleted, as well as associated uploads, tracks, favorites and listening history. This action is irreversible."
              },
              "header": "Delete this library?"
            }
          },
          "table": {
            "activity": {
              "firstSeen": "First seen",
              "followers": "Followers"
            },
            "audioContent": {
              "cachedSize": "Cached size",
              "totalSize": "Total size"
            },
            "library": {
              "description": "Description",
              "name": "Name"
            }
          },
          "warning": {
            "stats": "Statistics are computed from known activity and content on your instance, and do not reflect general activity for this object"
          }
        },
        "TagDetail": {
          "button": {
            "delete": "Delete"
          },
          "header": {
            "activity": "Activity",
            "audioContent": "Audio content",
            "tagData": "Tag data"
          },
          "link": {
            "albums": "Albums",
            "artists": "Artists",
            "django": "View in Django's admin",
            "localProfile": "Open local profile",
            "tracks": "Tracks"
          },
          "modal": {
            "delete": {
              "content": {
                "warning": "The tag will be removed and unlinked from any existing entity. This action is irreversible."
              },
              "header": "Delete this tag?"
            }
          },
          "table": {
            "activity": {
              "firstSeen": "First seen"
            },
            "tag": {
              "name": "Name"
            }
          }
        },
        "TrackDetail": {
          "button": {
            "delete": "Delete",
            "edit": "Edit",
            "remoteRefresh": "Refresh from remote server"
          },
          "header": {
            "activity": "Activity",
            "local": "Local",
            "trackData": "Track data"
          },
          "link": {
            "album": "Album",
            "albumArtist": "Album artist",
            "artist": "Artist",
            "django": "View in Django's admin",
            "domain": "Domain",
            "edits": "Edits",
            "libraries": "Libraries",
            "localProfile": "Open local profile",
            "musicbrainz": "Open on MusicBrainz",
            "remoteProfile": "Open remote profile",
            "reports": "Linked reports",
            "uploads": "Uploads"
          },
          "modal": {
            "delete": {
              "content": {
                "warning": "The track will be deleted, as well as associated uploads, favorites and listening history. This action is irreversible."
              },
              "header": "Delete this track?"
            }
          },
          "table": {
            "activity": {
              "favorited": "Favorited tracks",
              "firstSeen": "First seen",
              "listenings": "Listenings",
              "playlists": "Playlists"
            },
            "track": {
              "copyright": "Copyright",
              "description": "Description",
              "discNumber": "Disc number",
              "license": "License",
              "position": "Position",
              "title": "Title"
            },
            "trackData": {
              "cachedSize": "Cached size",
              "totalSize": "Total size"
            }
          },
          "warning": {
            "stats": "Statistics are computed from known activity and content on your instance, and do not reflect general activity for this object"
          }
        },
        "UploadDetail": {
          "button": {
            "delete": "Delete",
            "download": "Download"
          },
          "header": {
            "activity": "Activity",
            "audioContent": "Audio content",
            "local": "Local",
            "uploadData": "Upload data"
          },
          "link": {
            "account": "Account",
            "django": "View in Django's admin",
            "domain": "Domain",
            "importStatus": "Import status",
            "library": "Library",
            "remoteProfile": "Open remote profile",
            "type": "Type",
            "visibility": "Visibility"
          },
          "modal": {
            "delete": {
              "content": {
                "warning": "The upload will be removed. This action is irreversible."
              },
              "header": "Delete this upload?"
            }
          },
          "notApplicable": "N/A",
          "table": {
            "activity": {
              "accessedDate": "Accessed date",
              "firstSeen": "First seen"
            },
            "audioContent": {
              "bitrate": {
                "label": "Bitrate",
                "value": "{bitrate}/s"
              },
              "cachedSize": "Cached size",
              "duration": "Duration",
              "size": "Size",
              "track": "Track"
            },
            "upload": {
              "name": "Name"
            }
          }
        }
      },
      "moderation": {
        "AccountsDetail": {
          "button": {
            "addPolicy": "Add a moderation policy"
          },
          "description": {
            "policy": "Moderation policies help you control how your instance interact with a given domain or account"
          },
          "header": {
            "accountData": "Account data",
            "activePolicy": "This domain is subject to specific moderation rules",
            "activity": "Activty",
            "audioContent": "Audio content",
            "localAccount": "Local account",
            "noPolicy": "You don't have any rule in place for this account."
          },
          "link": {
            "albums": "Albums",
            "artists": "Artists",
            "channels": "Channels",
            "django": "View in Django's admin",
            "domain": "Domain",
            "libraries": "Libraries",
            "linkedReports": "Linked reports",
            "openProfile": "Open profile",
            "remoteProfile": "Open remote profile",
            "requests": "Requests",
            "tracks": "Tracks",
            "uploads": "Uploads"
          },
          "notApplicable": "N/A",
          "option": {
            "permission": {
              "library": "Library",
              "moderation": "Moderation",
              "settings": "Settings"
            }
          },
          "table": {
            "accountData": {
              "displayName": "Display name",
              "email": "Email address",
              "lastActivity": "Last activity",
              "lastChecked": "Last checked",
              "loginStatus": {
                "disabled": "Disabled",
                "enabled": "Enabled",
                "label": "Login status"
              },
              "permissions": "Permissions",
              "signupDate": "Sign-up date",
              "userType": "Type",
              "username": "Username"
            },
            "activity": {
              "emittedFollows": "Emitted library follows",
              "emittedMessages": "Emitted messages",
              "firstSeen": "First seen",
              "receivedFollows": "Received library follows"
            },
            "audioContent": {
              "cachedSize": "Cached size",
              "megabyte": "MB",
              "totalSize": "Total size",
              "uploadQuota": "Upload quota"
            }
          },
          "tooltip": {
            "uploadQuota": "Determine how much content the user can upload. Leave empty to use the default value of the instance."
          },
          "warning": {
            "stats": "Statistics are computed from known activity and content on your instance, and do not reflect general activity for this object"
          }
        },
        "Base": {
          "link": {
            "accounts": "Accounts",
            "domains": "Domains",
            "reports": "Reports",
            "userRequests": "User Requests"
          },
          "menu": {
            "secondary": "Secondary menu"
          },
          "title": "Moderation"
        },
        "DomainsDetail": {
          "button": {
            "addPolicy": "Add a moderation policy",
            "addToAllowList": "Add to allow-list",
            "refreshNodeInfo": "Refresh node info",
            "removeFromAllowList": "Remove from allow-list"
          },
          "description": {
            "policy": "Moderation policies help you control how your instance interact with a given domain or account"
          },
          "header": {
            "activePolicy": "This domain is subject to specific moderation rules",
            "activity": "Activty",
            "audioContent": "Audio content",
            "instanceData": "Instance data",
            "noPolicy": "You don't have any rule in place for this domain."
          },
          "link": {
            "albums": "Albums",
            "artists": "Artists",
            "channels": "Channels",
            "django": "View in Django's admin",
            "knownAccounts": "Known accounts",
            "libraries": "Libraries",
            "tracks": "Tracks",
            "uploads": "Uploads",
            "website": "Open website"
          },
          "notApplicable": "N/A",
          "table": {
            "activity": {
              "emittedFollows": "Emitted library follows",
              "emittedMessages": "Emitted messages",
              "firstSeen": "First seen",
              "receivedFollows": "Received library follows"
            },
            "audioContent": {
              "cachedSize": "Cached size",
              "totalSize": "Total size"
            },
            "instanceData": {
              "domainName": "Name",
              "inAllowList": {
                "false": "No",
                "label": "Is present on allow-list",
                "true": "Yes"
              },
              "lastChecked": "Last checked",
              "nodeInfoStatus": {
                "label": "Status",
                "value": "Error while fetching node info"
              },
              "software": {
                "label": "Software",
                "value": "{name} ({version})"
              },
              "totalUsers": "Total users"
            }
          },
          "warning": {
            "stats": "Statistics are computed from known activity and content on your instance, and do not reflect general activity for this object"
          }
        },
        "DomainsList": {
          "button": {
            "add": "Add"
          },
          "header": {
            "domains": "Domains",
            "failure": "Error while creating domain"
          },
          "label": {
            "addDomain": "Add a domain",
            "addToAllowList": "Add to allow-list"
          },
          "title": "Domains"
        },
        "ReportsList": {
          "header": {
            "reports": "Reports"
          },
          "label": {
            "search": "Search",
            "status": "Status"
          },
          "option": {
            "status": {
              "all": "All",
              "resolved": "Resolved",
              "unresolved": "Unresolved"
            }
          },
          "ordering": {
            "direction": {
              "ascending": "Ascending",
              "descending": "Descending",
              "label": "Order"
            },
            "label": "Ordering"
          },
          "placeholder": {
            "search": "Search by account, summary, domain…"
          },
          "title": "Reports"
        },
        "RequestsList": {
          "header": {
            "userRequests": "User Requests"
          },
          "label": {
            "search": "Search",
            "status": "Status"
          },
          "option": {
            "status": {
              "all": "All",
              "approved": "Approved",
              "pending": "Pending",
              "refused": "Refused"
            }
          },
          "ordering": {
            "direction": {
              "ascending": "Ascending",
              "descending": "Descending",
              "label": "Order"
            },
            "label": "Ordering"
          },
          "placeholder": {
            "search": "Search by username"
          },
          "title": "User Requests"
        }
      },
      "users": {
        "Base": {
          "link": {
            "invitations": "Invitations",
            "users": "Users"
          },
          "menu": {
            "secondary": "Secondary menu"
          },
          "title": "Manage users"
        }
      }
    },
    "auth": {
      "Callback": {
        "header": {
          "loggingIn": "Logging in…"
        }
      },
      "EmailConfirm": {
        "header": {
          "failure": "Could not confirm your e-mail address",
          "success": "E-mail address confirmed"
        },
        "label": {
          "confirmationCode": "Confirmation code"
        },
        "link": {
          "back": "Return to login",
          "login": "Proceed to login"
        },
        "message": {
          "success": "You can now use the service without limitations"
        },
        "title": "Confirm your e-mail address"
      },
      "Login": {
        "header": {
          "login": "Log in to your Funkwhale account"
        },
        "title": "Log in"
      },
      "PasswordReset": {
        "button": {
          "requestReset": "Ask for a password reset"
        },
        "header": {
          "failure": "Error while asking for a password reset",
          "reset": "Reset your password"
        },
        "help": {
          "form": "Use this form to request a password reset. We will send an e-mail to the given address with instructions to reset your password."
        },
        "label": {
          "email": "Account's e-mail address"
        },
        "link": {
          "back": "Back to login"
        },
        "placeholder": {
          "email": "Enter the e-mail address linked to your account"
        },
        "title": "Reset your password"
      },
      "PasswordResetConfirm": {
        "button": {
          "update": "Update your password"
        },
        "header": {
          "failure": "Error while changing your password",
          "success": "Password updated successfully"
        },
        "label": {
          "newPassword": "New password"
        },
        "link": {
          "back": "Back to login",
          "login": "Proceed to login"
        },
        "message": {
          "requestSent": "If the e-mail address provided in the previous step is valid and linked to a user account, you should receive an e-mail with reset instructions in the next couple of minutes.",
          "success": "Your password has been updated successfully."
        },
        "title": "Change your password"
      },
      "Plugins": {
        "title": "Manage plugins"
      },
      "ProfileActivity": {
        "header": {
          "playlists": "Playlists",
          "recentlyFavorited": "Recently favorited",
          "recentlyListened": "Recently listened"
        }
      },
      "ProfileBase": {
        "label": {
          "self": "This is you!"
        },
        "link": {
          "activity": "Activity",
          "domainView": "View on {domain}",
          "moderation": "Open in moderation interface",
          "overview": "Overview"
        },
        "title": "{username}'s profile"
      },
      "ProfileOverview": {
        "button": {
          "cancel": "Cancel",
          "createChannel": "Create channel",
          "next": "Next step",
          "previous": "Previous step"
        },
        "header": {
          "channels": "Channels",
          "libraries": "User Libraries",
          "sharedLibraries": "This user shared the following libraries"
        },
        "link": {
          "addNew": "Add New"
        },
        "modal": {
          "createChannel": {
            "artist": {
              "header": "Artist channel"
            },
            "header": "Create channel",
            "podcast": {
              "header": "Podcast channel"
            }
          }
        }
      },
      "Signup": {
        "header": {
          "createAccount": "Create a Funkwhale account"
        },
        "title": "Sign up"
      }
    },
    "channels": {
      "DetailBase": {
        "button": {
          "cancel": "Cancel",
          "confirm": "Delete",
          "delete": "Delete…",
          "edit": "Edit…",
          "embed": "Embed",
          "play": "Play",
          "updateChannel": "Update channel",
          "upload": "Upload"
        },
        "header": {
          "artistChannel": "Artist channel",
          "podcastChannel": "Podcast channel"
        },
        "link": {
          "channelEpisodes": "All episodes",
          "channelOverview": "Overview",
          "channelTracks": "Tracks",
          "domainView": "View on {domain}",
          "mirrored": "Mirrored from {domain}",
          "moderation": "Open in moderation interface"
        },
        "meta": {
          "episodes": "No episodes | {n} episode | {n} episodes",
          "listenings": "No listenings | {n} listening | {n} listenings",
          "subscribers": "No subscribers | {n} subscriber | {n} subscribers",
          "tracks": "No tracks | {n} track | {n} tracks"
        },
        "modal": {
          "delete": {
            "content": {
              "warning": "The channel will be deleted, as well as any related files and data. This action is irreversible."
            },
            "header": "Delete this Channel?"
          },
          "embed": {
            "header": "Embed this artist work on your website"
          },
          "subscribe": {
            "fediverse": {
              "content": {
                "help": "If you're using Mastodon or other fediverse applications, you can subscribe to this account:"
              },
              "header": "Subscribe on the Fediverse"
            },
            "funkwhale": {
              "header": "Subscribe on Funkwhale"
            },
            "header": "Subscribe to this channel",
            "rss": {
              "content": {
                "help": "Copy paste the following URL in your favorite podcatcher:"
              },
              "header": "Subscribe via RSS"
            }
          }
        },
        "title": "Channel"
      },
      "DetailOverview": {
        "header": {
          "albums": "Albums",
          "latestEpisodes": "Latest episodes",
          "latestTracks": "Latest tracks",
          "series": "Series",
          "uploadsFailure": "Some uploads couldn't be published",
          "uploadsProcessing": "Uploads are being processed",
          "uploadsSuccess": "Uploads published successfully"
        },
        "link": {
          "addAlbum": "Add new",
          "erroredUploads": "View errored uploads",
          "skippedUploads": "View skipped uploads"
        },
        "message": {
          "processing": "Your uploads are being processed by Funkwhale and will be live very soon."
        },
        "meta": {
          "progress": "Processed uploads: {finished}/{total}"
        }
      },
      "SubscriptionsList": {
        "button": {
          "cancel": "Cancel",
          "subscribe": "Subscribe"
        },
        "link": {
          "addNew": "Add new"
        },
        "modal": {
          "subscription": {
            "header": "Subscription"
          }
        },
        "placeholder": {
          "search": "Filter by name…"
        },
        "title": "Subscribed Channels"
      }
    },
    "content": {
      "Base": {
        "link": {
          "libraries": "Libraries",
          "tracks": "Tracks"
        },
        "menu": {
          "secondary": "Secondary menu"
        },
        "title": "Add content"
      },
      "Home": {
        "button": {
          "start": "Get started"
        },
        "description": {
          "channel": {
            "1": "If you are a musician or a podcaster, channels are designed for you!",
            "2": "Share your work publicly and get subscribers on Funkwhale, the Fediverse or any podcasting application."
          },
          "follow": "Follow libraries from other users to get access to new music. Public libraries can be followed immediately, while following a private library requires approval from its owner.",
          "upload": "Upload your personal music library to Funkwhale to enjoy it from anywhere and share it with friends and family."
        },
        "header": {
          "channel": "Publish your work in a channel",
          "follow": "Follow remote libraries",
          "upload": "Upload third-party content in a library"
        },
        "help": {
          "uploadQuota": "This instance offers up to {quota} of storage space for every user."
        },
        "title": "Add and manage content"
      },
      "libraries": {
        "Card": {
          "button": {
            "upload": "Upload"
          },
          "label": {
            "size": "Total size of the files in this library"
          },
          "link": {
            "details": "Library Details"
          },
          "meta": {
            "tracks": "No tracks | {n} track | {n} tracks"
          }
        },
        "FilesTable": {
          "action": {
            "delete": "Delete",
            "restartImport": "Restart import"
          },
          "button": {
            "showStatus": "Show information about the upload status for this track"
          },
          "empty": {
            "noTracks": "No tracks have been added to this libray yet"
          },
          "label": {
            "importStatus": "Import status",
            "search": "Search"
          },
          "notApplicable": "N/A",
          "option": {
            "status": {
              "all": "All",
              "draft": "Draft",
              "failed": "Failed",
              "finished": "Finished",
              "pending": "Pending",
              "skipped": "Skipped"
            }
          },
          "ordering": {
            "direction": {
              "ascending": "Ascending",
              "descending": "Descending",
              "label": "Ordering direction"
            },
            "label": "Ordering"
          },
          "pagination": {
            "results": "Showing results {start}-{end} on {total}"
          },
          "placeholder": {
            "search": "Search by domain, title, artist, album…"
          },
          "table": {
            "file": {
              "header": {
                "album": "Album",
                "artist": "Artist",
                "duration": "Duration",
                "importStatus": "Import status",
                "size": "Size",
                "title": "Title",
                "uploadDate": "Upload date"
              }
            }
          }
        },
        "Form": {
          "button": {
            "confirm": "Delete library",
            "create": "Create library",
            "delete": "Delete",
            "update": "Update library"
          },
          "description": {
            "library": "Libraries help you organize and share your music collections. You can upload your own music collection to Funkwhale and share it with your friends and family.",
            "visibility": "You are able to share your library with other people, regardless of its visibilty."
          },
          "header": {
            "failure": "Error"
          },
          "label": {
            "description": "Description",
            "name": "Name",
            "visibility": "Visibility"
          },
          "message": {
            "libraryCreated": "Library created",
            "libraryDeleted": "LIbrary deleted",
            "libraryUpdated": "Library updated"
          },
          "modal": {
            "delete": {
              "content": {
                "warning": "The library and all its tracks will be deleted. This can not be undone."
              },
              "header": "Delete this library?"
            }
          },
          "placeholder": {
            "description": "This library contains my personal music, I hope you like it.",
            "name": "My awesome library"
          }
        },
        "Home": {
          "empty": {
            "noLibrary": "Looks like you don't have a library, it's time to create one."
          },
          "header": {
            "libraries": "My libraries"
          },
          "link": {
            "createLibrary": "Create a new library"
          },
          "loading": {
            "libraries": "Loading libraries…"
          }
        },
        "Quota": {
          "button": {
            "purge": "Purge"
          },
          "header": {
            "currentUsage": "Current usage"
          },
          "label": {
            "currentUsage": "{amount} used on {max} allowed",
            "errored": "Errored files",
            "pending": "Pending files",
            "percentUsed": "{progress}%",
            "skipped": "Skipped files"
          },
          "link": {
            "viewFiles": "View files"
          },
          "loading": {
            "currentUsage": "Loading usage data…"
          },
          "modal": {
            "purgeErrored": {
              "content": {
                "description": "Removes uploaded tracks that could not be processed by the server completely, adding the corresponding data to your quota."
              },
              "header": "Purge errored files?"
            },
            "purgePending": {
              "content": {
                "description": "Removes uploaded but yet to be processed tracks completely, adding the corresponding data to your quota."
              },
              "header": "Purge pending files?"
            },
            "purgeSkipped": {
              "content": {
                "description": "Removes uploaded tracks skipped during the import processes completely, adding the corresponding data to your quota."
              },
              "header": "Purge skipped files?"
            }
          }
        }
      },
      "remote": {
        "Card": {
          "button": {
            "cancel": "Cancel follow request",
            "follow": "Follow",
            "pending": "Follow request pending approval",
            "unfollow": "Unfollow"
          },
          "error": {
            "follow": "Cannot follow remote library: {error}",
            "unfollow": "Cannot unfollow remote library: {error}"
          },
          "label": {
            "scanFailure": "Problem during scanning",
            "scanPartialSuccess": "Scanned with errors",
            "scanPending": "Scan pending",
            "scanProgress": "Scanning ({progress})",
            "scanSuccess": "Scanned",
            "sharingLink": "Sharing link"
          },
          "link": {
            "scan": "Scan now",
            "scanDetails": "Details"
          },
          "message": {
            "scanLaunched": "Scan launched",
            "scanSkipped": "Scan skipped (previous scan is too recent)"
          },
          "meta": {
            "failedTracks": "Failed tracks: {tracks}",
            "lastUpdate": "Last update: ",
            "tracks": "No tracks | {n} track | {n} tracks"
          },
          "modal": {
            "unfollow": {
              "content": {
                "warning": "By unfollowing this library, you loose access to its content."
              },
              "header": "Unfollow this libary?"
            }
          },
          "tooltip": {
            "private": "This library is private and your approval from its owner is needed to access its content",
            "public": "This library is public and you can access its content freely"
          }
        },
        "Home": {
          "button": {
            "refresh": "Refresh"
          },
          "description": {
            "remoteLibraries": "Remote libraries are owned by other users on the network. You can access them as long as they are public or you are granted access."
          },
          "header": {
            "knownLibraries": "Known libraries",
            "remoteLibraries": "Remote libraries"
          },
          "loading": {
            "remoteLibraries": "Loading remote libraries…"
          }
        },
        "ScanForm": {
          "button": {
            "submit": "Submit search"
          },
          "header": {
            "failure": "Could not fetch remote library"
          },
          "label": {
            "search": "Search a remote library"
          },
          "placeholder": {
            "url": "Enter a library URL"
          }
        }
      }
    },
    "library": {
      "DetailAlbums": {
        "empty": {
          "follow": "You may need to follow this library to see its content.",
          "upload": "This library is empty, you should upload something in it!"
        }
      },
      "DetailOverview": {
        "empty": {
          "follow": "You may need to follow this library to see its content.",
          "upload": "This library is empty, you should upload something in it!"
        }
      },
      "DetailTracks": {
        "empty": {
          "follow": "You may need to follow this library to see its content.",
          "upload": "This library is empty, you should upload something in it!"
        }
      },
      "Edit": {
        "button": {
          "accept": "Accept",
          "reject": "Reject"
        },
        "empty": {
          "noFollowers": "Nobody is following this library"
        },
        "header": {
          "followers": "Followers",
          "libraryContents": "Library contents"
        },
        "loading": {
          "followers": "Loading followers…"
        },
        "table": {
          "action": {
            "header": {
              "action": "Action",
              "date": "Date",
              "status": "Status",
              "user": "User"
            },
            "status": {
              "accepted": "Accepted",
              "pending": "Pending approval",
              "rejected": "Rejected"
            }
          }
        }
      },
      "LibraryBase": {
        "button": {
          "edit": "Edit",
          "upload": "Upload"
        },
        "description": {
          "sharingLink": "Share this link with other users so they can request access to this library by copy-pasting it in their pod search bar."
        },
        "label": {
          "instance": "Restricted",
          "private": "Private",
          "public": "Public",
          "sharingLink": "Sharing link"
        },
        "link": {
          "albums": "Albums",
          "artists": "Artists",
          "domain": "View on {domain}",
          "moderation": "Open in moderation interface",
          "owner": "Owned by {username}",
          "tracks": "Tracks"
        },
        "meta": {
          "tracks": "No tracks | {n} track | {n} tracks"
        },
        "title": "Library",
        "tooltip": {
          "instance": "This library is restricted to users on this pod only",
          "private": "This library is private and your approval from its owner is needed to access its content",
          "public": "This library is public and you can access its content freely"
        }
      }
    },
    "playlists": {
      "Detail": {
        "button": {
          "cancel": "Cancel",
          "confirm": "Delete playlist",
          "delete": "Delete",
          "edit": "Edit",
          "embed": "Embed",
          "playAll": "Play all",
          "stopEdit": "Stop Editing"
        },
        "empty": {
          "noTracks": "There are no tracks in this playlist yet"
        },
        "header": {
          "tracks": "Tracks"
        },
        "meta": {
          "tracks": "Playlist containing {n} track, by {username} | Playlist containing {n} tracks, by {username}"
        },
        "modal": {
          "delete": {
            "content": {
              "warning": "This will completely delete this playlist and cannot be undone."
            },
            "header": "Do you want to delete the playlist {playlist}?"
          },
          "embed": {
            "header": "Embed this playlist on your website"
          }
        },
        "title": "Playlist"
      },
      "List": {
        "button": {
          "create": "Create a playlist",
          "manage": "Manage your playlists",
          "search": "Search"
        },
        "empty": {
          "noResults": "No results matching your query"
        },
        "header": {
          "browse": "Browsing playlists",
          "playlists": "Playlists"
        },
        "label": {
          "search": "Search"
        },
        "ordering": {
          "direction": {
            "ascending": "Ascending",
            "descending": "Descending",
            "label": "Order"
          },
          "label": "Ordering"
        },
        "pagination": {
          "results": "Results per page"
        },
        "placeholder": {
          "search": "Enter playlist name…"
        }
      }
    },
    "radios": {
      "Detail": {
        "button": {
          "confirm": "Delete radio",
          "delete": "Delete",
          "edit": "Edit…"
        },
        "empty": {
          "noTracks": "No tracks have been hadded to this radio yet"
        },
        "header": {
          "radio": "Radio containing {tracks} tracks, by ",
          "tracks": "Tracks"
        },
        "modal": {
          "delete": {
            "content": {
              "warning": "This will completely delete this radio and cannot be undone."
            },
            "header": "Do you want to delete the radio {radio}?"
          }
        },
        "title": "Radio"
      }
    }
  }
}
